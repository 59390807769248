import React, { useEffect, useState, useRef } from "react";
import { PAYMENT_URL } from "../../Config/Config";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
// import ImageGallery from './ImageGallery';
import MapComponent from './MapComponent';
import 'leaflet/dist/leaflet.css';
import {
  getPropertyById,
  getAllEventsOnBasisOFCity,
} from "../../Redux/Features/listReducer/listSlice";
import { Galleria } from "primereact/galleria";
import { formatedDateTime } from "../../Utils/constant/commonFunction";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import {
  manualUpdateListing,
  includeExcludeUpdate,
  autoUpdateListing,
  HostawayLIstings,
  resetSingleAndMultipleProperty
} from "../../Redux/Features/authReducer/authSlice";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { FilterMatchMode } from "primereact/api";
import { NavLink } from "react-router-dom";
import { LISTING } from "../../Routes/Constant";
import ImgNotAvailable from "../../Assets/images/no_image.png";
import { BreadCrumb } from "primereact/breadcrumb";
import { DateFormat } from "../../Utils/constant/commonFunction";
import SettingDialog from "../../Components/PropertyListing/SettingDialog";
import {
  setPaymentId,
  removePaymentId,
} from "../../Redux/Features/LocalStorage";

function PropertyDetail() {
  const dispatch = useDispatch();
  const [property, setProperty] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState("");
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadings, setIsLoadings] = useState(false)
  const [isLoad, setIsLoad] = useState(false);
  const [isResetLoading, setIsResetLoading] = useState(false);
  const [event, setEvent] = useState([]);
  const [attractions, setAttractions] = useState([]);
  const [historyDetails, setHistoryDetails] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [monthlyRevenue, setMonthlyRevenue] = useState("");
  const [percentageChange, setPercentageChange] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState({
    title: "",
    descriptionInclude: "",
    descriptionExclude: "",
    isBullet: "",
    descriptionAdditional: "",
    bullet_option: "",
  });

  const [isProgressBar, setIsProgressBar] = useState(false);
  const [touristAttraction, setTouristAttraction] = useState([]);
  const [eventAttraction, setEventAttraction] = useState({});
  const [touristAttractionData, setTouristAttractionData] = useState([]);
  const [eventAttractionData, setEventAttractionData] = useState([]);
  const [selectedTouristAttraction, setSelectedTouristAttraction] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const galleria = useRef(null);
  const navigate = useNavigate();

  const { propertyDetail, getAllEventsOnBasisOFCityData } = useSelector(
    (state) => state.List);
  const imageLength = property?.hostawayResultData?.listingImages?.length || property?.hostawayResultData?.pictures?.original?.length || 0;
  const imageUrl1 = property?.hostawayResultData?.listingImages?.[0]?.url || property?.hostawayResultData?.pictures?.[0]?.original || process.env.PUBLIC_URL + ImgNotAvailable;
  const imageUrl2 = property?.hostawayResultData?.listingImages?.[1]?.url || property?.hostawayResultData?.pictures?.[1]?.original || process.env.PUBLIC_URL + ImgNotAvailable;
  const imageUrl3 = property?.hostawayResultData?.listingImages?.[2]?.url || property?.hostawayResultData?.pictures?.[2]?.original || process.env.PUBLIC_URL + ImgNotAvailable;
  const imageUrl4 = property?.hostawayResultData?.listingImages?.[3]?.url || property?.hostawayResultData?.pictures?.[3]?.original || process.env.PUBLIC_URL + ImgNotAvailable;
  const imageUrl5 = property?.hostawayResultData?.listingImages?.[4]?.url || property?.hostawayResultData?.pictures?.[4]?.original || process.env.PUBLIC_URL + ImgNotAvailable;
  const airbnbListingUrl = propertyDetail?.data?.airbnbUrl;

  const userROWID = localStorage.getItem('EditdID');

  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setIsLoading(false);
    }, 4000);

    return () => {
      clearTimeout(loadingTimer);
    };
  }, []);

  useEffect(() => {
    if (getAllEventsOnBasisOFCityData) {
      setEvent(getAllEventsOnBasisOFCityData.Data);
    }
  }, [getAllEventsOnBasisOFCityData]);

  useEffect(() => {
    if (propertyDetail) {
      setAttractions(propertyDetail?.data?.touristAttraction);
    }
  }, [propertyDetail]);

  useEffect(() => {
    dispatch(
      getAllEventsOnBasisOFCity({ recordId: userROWID })
    );
  }, [userROWID]);

  useEffect(() => {
    dispatch(
      getPropertyById({
        recordId: userROWID,
      })
    );
  }, [dispatch, userROWID]);

  useEffect(() => {
    if (propertyDetail != "") {
      setProperty(propertyDetail?.data);
      setPropertyDetails(propertyDetail?.data);
      setHistoryDetails(propertyDetail?.auditLogData);
      setMonthlyRevenue(propertyDetail?.monthlyRevenue);
      setPercentageChange(propertyDetail?.monthlyRevenue.percentageChange);

      // Break the description on full stop and join with a line break
      const description = propertyDetail?.data?.description;
      if (description) {
        const formattedDescription = description.split(/[\.\!\?]/).join('.<br/><br/>'); // Break
        setDescription(formattedDescription);
      }
    }
  }, [propertyDetail]);

  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const getTruncatedDescription = (desc) => {
    const words = desc.split(' ');
    if (words.length > 100) {
      return words.slice(0, 100).join(' ') + '...';
    }
    return desc;
  };

  useEffect(() => {
    if (propertyDetail?.data?.addedTouristAttraction?.length > 0) {
      setTouristAttractionData(propertyDetail?.data?.addedTouristAttraction)
    }
  }, [propertyDetail?.data?.addedTouristAttraction])

  useEffect(() => {
    if (propertyDetail?.data?.eventData?.length > 0) {
      setEventAttractionData(propertyDetail?.data?.eventData)
    }
  }, [propertyDetail?.data?.eventData])

  const saveDescription = (propertyDetails) => {
    dispatch(
      includeExcludeUpdate({
        recordId: propertyDetails?.recordId,
        title: propertyDetails.title,
        descriptionInclude: propertyDetails.descriptionInclude,
        descriptionExclude: propertyDetails.descriptionExclude,
        descriptionAdditional: propertyDetails.descriptionAdditional,
        bullet_option: propertyDetails.isBullet,
      })
    );
    dispatch(getPropertyById({ recordId: userROWID }))
    setIsEditing(false);
    return;
  };

  const handleCheckbox = (e, attractionsItem) => {
    const { checked } = e.target;

    if (checked) {
      // Add the selected attraction to the array
      if (!selectedTouristAttraction.some((attraction) => JSON.stringify(attraction) === JSON.stringify(attractionsItem))) {
        // Add the selected attraction to the array
        setSelectedTouristAttraction((prevAttractions) => [...prevAttractions, attractionsItem]);
        setTouristAttraction((prevAttractions) => [...prevAttractions, attractionsItem]);
      }

    } else {
      // Remove the deselected attraction from the array
      setSelectedTouristAttraction((prevAttractions) =>
        prevAttractions.filter((attraction) => JSON.stringify(attraction) !== JSON.stringify(attractionsItem))
      );
    }
  };

  const handleEventCheckbox = (e, eventItem) => {
    if (e.target.checked) {
      setSelectedEvent(eventItem.eventName);
      setEventAttraction(eventItem)
    } else {
      setSelectedEvent({});
      setEventAttraction({})
    }
  }

  const addEvents = (rowId) => {
    const payload = {
      recordId: rowId,
    };
    if (selectedTouristAttraction.length > 0) {
      payload.addedTouristAttraction =
        selectedTouristAttraction
    }
    const objLength = Object.keys(eventAttraction).length;
    if (objLength > 0) {
      payload.eventData = [
        {
          name: eventAttraction?.eventName,
          venue: eventAttraction?.venue,
          startDate: eventAttraction?.startDate,
        },
      ];
    }

    dispatch(includeExcludeUpdate(payload))
      .unwrap()
      .then((result) => {
        setSelectedTouristAttraction([])
        setEventAttraction({})
        setSelectedEvent({})
        dispatch(getPropertyById({ recordId: rowId }));
      });
  };


  const itemTemplate = (item) => {
    return (
      <img
        src={item.url}
        onError={(e) => {
          e.target.src =
            process.env.PUBLIC_URL + "/Assets/images/house_placeholder.png"; // replace with a default image
        }}
        alt={item.alt}
        style={{ width: "100%", display: "block" }}
      />
    );
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item.url}
        onError={(e) => {
          e.target.src =
            process.env.PUBLIC_URL + "/Assets/images/house_placeholder.png"; // replace with a default image
        }}
        alt={item.alt}
        style={{ display: "block" }}
      />
    );
  };

  const PropertySyncConfirm = (row) => {
    confirmDialog({
      message: (
        <div className="mx-3 my-2 text-center">
          <h5 className="exDemiBold fs-2 text-center text-navy my-2">  <i className="pi pi-exclamation-triangle"></i> &nbsp; Are you sure?</h5>
          <p className="text-navy-light text-center w-75 m-auto">
            Sync process is valid for only one day.
          </p>
        </div>
      ),
      closable: false, // Add this line to prevent closing the dialog
      // dismissableMask: true, // Allow clicking outside to close

      defaultFocus: "accept",
      accept: () => syncProperty(),
      reject: () => PropertySyncRejectBullet(row),
    });

  };
  const PropertySyncRejectBullet = () => { };

  const ResetConfirm = (row) => {
    confirmDialog({
      message: (
        <div className="mx-3 my-2 text-center">
          <h5 className="exDemiBold fs-2 text-center text-navy my-2">
            <i className="pi pi-exclamation-triangle"></i> &nbsp; Are you sure?</h5>
          <p className="text-navy-light text-center w-75 m-auto">
            Do you want to reset your property listing details?
          </p>
        </div>
      ),
      closable: false, // Add this line to prevent closing the dialog
      defaultFocus: "accept",
      accept: () => handleRestoreNow(),
      reject: () => restoreRejectBullet(),
    });

  };

  const syncProperty = async () => {
    setTimeout(() => {
      setIsLoadings(true);
      setIsProgressBar(true);
    }, 100);
    removePaymentId();
    if (userROWID) {
      setIsLoad(true);
      try {
        const { data } = await dispatch(
          manualUpdateListing({
            recordId: [userROWID],
            redirectUrl: PAYMENT_URL

          })
        ).unwrap();
        await dispatch(HostawayLIstings()).unwrap();
        setIsLoad(false);

        setTimeout(() => {
          setIsLoadings(false);
          setIsProgressBar(false);
        }, 3000);

        if (data?.checkoutUrl) {
          setPaymentId(data?.id);
          window.location.href = data?.checkoutUrl;
        } else {
          console.error("Checkout URL is missing");
        }
      }
      catch (error) {
        console.error(error);
        setIsLoad(false);
        setIsLoadings(false);
        setIsProgressBar(false);
      }
    }
  };

  const handleRestoreNow = async () => {
    setTimeout(() => {
      setIsResetLoading(true);
    }, 100);
    if (userROWID) {
      setIsResetLoading(true);
      try {
        const result = await dispatch(
          resetSingleAndMultipleProperty({
            primaryIds: [userROWID]
          })
        ).unwrap();
        dispatch(getPropertyById({ recordId: userROWID }))
          .unwrap();
        setIsResetLoading(false);

        setTimeout(() => {
          setIsResetLoading(false);
        }, 2000);
      }
      catch (error) {
        setIsResetLoading(false);
      }
    }
  };

  const restoreRejectBullet = () => { };

  const automateProperty = async (row) => {
    setLoading(true);
    removePaymentId();
    if (row?.autoUpdateStatus == 0) {
      setIsLoading(true);
      const { data } = await dispatch(
        autoUpdateListing({
          primaryPropertyId: row?.id,
          redirectUrl: PAYMENT_URL
        })
      )
        .unwrap();
      await dispatch(getPropertyById({
        recordId: row?.recordId,
      }))
        .unwrap();
      dispatch(HostawayLIstings())
        .unwrap();
      setIsLoading(false);
      setLoading(false);
      if (data?.checkoutUrl) {
        setPaymentId(data?.id);
        window.location.href = data?.checkoutUrl;
      } else {
        console.error("Checkout URL is missing");
        setLoading(false);
      }
    } else {
      confirm(row);
    }
  };
  const confirm = (row) => {
    confirmDialog({
      header: (
        <img
          className="propertyImg  me-2"
          src={
            row?.thumbnailUrl ||
            process.env.PUBLIC_URL + "/Assets/images/house_placeholder.png"
          }
          onError={(e) => {
            e.target.src =
              process.env.PUBLIC_URL + "/Assets/images/house_placeholder.png"; // replace with a default image
          }}
          alt="No Image"
        />
      ),
      message: (
        <div className="mx-3 my-2 text-center">
          <h6 className="fs-18 text-navy mt-3 exMedium">
            {row?.name}
          </h6>
          {/* <p className="text-navy-light">Harvest Horizon Homestead</p> */}
          <h5 className="exDemiBold fs-2 text-center text-navy my-2">
            <i className="pi pi-exclamation-triangle"></i> &nbsp; Are you sure?</h5>
          <p className="text-navy-light text-center w-75 m-auto">
            If you disable this listing, you will not be able to see future
            updates.
          </p>
        </div>
      ),
      closable: false, // Add this line to prevent closing the dialog
      defaultFocus: "accept",
      accept: () => acceptBullet(row),
      reject: () => rejectBullet(row),
    });
  };

  const acceptBullet = (row) => {
    setLoading(true);
    dispatch(
      autoUpdateListing({
        primaryPropertyId: row?.id,
      })
    )
      .unwrap()
      .then((result) => {
        dispatch(getPropertyById({ recordId: row?.recordId }))
          .unwrap();
        dispatch(HostawayLIstings()).unwrap();
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const rejectBullet = (row) => { };

  const goToNotFoundPage = () => {
    navigate("/not-found");
  };

  const onTextFieldChange = (e) => {
    setPropertyDetails({
      ...propertyDetails,
      [e.target.name]: e.target.value,
    });
  };

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const tableFooter = () => {


    return (
      <>
        <div className="text-end p-2 me-3">
          <NavLink
            to={`/updated_history/${userROWID}`}
            className="text-purple ms-auto"

          >
            View All
          </NavLink>
        </div>
      </>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        {/* <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </IconField> */}
      </div>
    );
  };

  const titleBodyTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex align-items-center">
          <p className="fs-14 m-0 lh-lg">{rowData.title}</p>
        </div>
      </>
    );
  };

  const dateBodyTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex align-items-center">
          <p className="fs-14 m-0 lh-lg">{DateFormat(rowData?.createdAt)}</p>
        </div>
      </>
    );
  };
  const includedData = (rowData) => {
    return (
      <>
        <Tag
          className={
            rowData.included == "Event"
              ? "px-3 py-2 mint-30 text-navy"
              : rowData.included == "Attraction"
                ? "px-3 py-2 lime-30 text-navy"
                : "px-3 py-2 purple-15 text-navy"
          }
          value={rowData.logType}
          rounded
        ></Tag>
      </>
    );
  };
  const allowExpansion = (rowData) => {
    return rowData;
  };
  const onRowExpand = (event) => {
    // toast.current.show({
    //   severity: "info",
    //   summary: "Product Expanded",
    //   detail: event.data.name,
    //   life: 3000,
    // });
  };
  const onRowCollapse = (event) => {
    // toast.current.show({
    //   severity: "success",
    //   summary: "Product Collapsed",
    //   detail: event.data.name,
    //   life: 3000,
    // });
  };

  const onRowClick = (e) => {
    const rowId = e.data.id;
    const currentExpandedRows = { ...expandedRows };

    if (currentExpandedRows[rowId] === true) {
      delete currentExpandedRows[rowId];
    } else {
      currentExpandedRows[rowId] = true;
    }
    setExpandedRows(Object.keys(currentExpandedRows).length ? currentExpandedRows : null);
  }

  const rowExpansionTemplate = (rowData) => {
    return (
      <div className="p-3">
        <h6 className="exMedium fs-18 color-black">Summary</h6>
        <p>{rowData?.airbnbSummary}</p>
        <h6 className="exMedium fs-18 color-black">Space</h6>
        <p>{rowData?.airbnbSpace}</p>
        <div class="updated_analytics mt-4">
          <div class="row gy-4">
            <div className="col-6">
              <div className="card border-0 shadow-sm d-flex align-items-center justify-content-evenly flex-row rounded-17 p-3 purple-15">
                <h3 className="exBold fs-2 mb-0">
                  ${historyDetails?.totalRevenue || 0}K
                </h3>
                <div>
                  <p className="mb-1 exMedium fs-6">Total Revenue</p>
                  <button className="btn btn-sm rounded-pill me-2 btn-secondary purple-25 text-navy border-0">
                    <img
                      className="me-1"
                      src={
                        process.env.PUBLIC_URL +
                        "/Assets/images/icons/growth.svg"
                      }
                      alt=""
                    />
                    {historyDetails?.percentageChange || 0}%
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="col-4">
              <div className="card border-0 shadow-sm d-flex align-items-center justify-content-evenly flex-row rounded-17 p-3 lime-20">
                <h3 className="exBold fs-2 mb-0">2.2</h3>
                <div>
                  <p className="mb-1 exMedium fs-6">BookingPace Momentum</p>
                  <button className="btn btn-sm rounded-pill me-2 btn-secondary lime-40 text-navy border-0">
                    <img
                      className="me-1 rotate-180"
                      src={
                        process.env.PUBLIC_URL +
                        "/Assets/images/icons/growth.svg"
                      }
                      alt=""
                    />
                    34%
                  </button>
                </div>
              </div>
            </div> */}
            <div className="col-6">
              <div className="card border-0 shadow-sm d-flex align-items-center justify-content-evenly flex-row rounded-17 p-3 mint-30 h-100">
                <h3 className="exBold fs-2 mb-0 me-2">
                  {historyDetails?.totalNumberOfBooking || 0}
                </h3>
                <div>
                  <p className="mb-1 exMedium fs-6">Number of Bookings</p>
                  {/* <button className="btn btn-sm rounded-pill btn-secondary mint-40 text-navy border-0">
                    <img
                      className="me-1"
                      src={
                        process.env.PUBLIC_URL +
                        "/Assets/images/icons/growth.svg"
                      }
                      alt=""
                    />
                    18%
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const header = renderHeader();

  const items = [
    { label: "Listing", url: LISTING },
    { label: "Listing Details" },
  ];
  const home = { icon: "pi pi-home", url: "/" };

  const handleButtonClick = (row) => {
    setVisible(true);
    dispatch(getPropertyById({ recordId: row?.recordId }));
  };

  return (
    <>
      <section id="content-wrapper">
        <div class="property_details">
          <BreadCrumb
            model={items}
            home={home}
            className="border-0 text-decoration-none mb-2"
          />

          {
            isProgressBar &&
            (
              <div className="cardProgressBar2">
                <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="transparent" animationDuration=".5s" />
              </div>
            )
          }

          <div class="d-flex align-items-center justify-content-between flex-wrap">
            <div class="welcome-user">
              <h2 class="content-title mb-0 exDemiBold mb-2">
                Property Details
              </h2>
              <p class="text-navy-light mb-3">
                Last updated: {formatedDateTime(property?.updatedAt)}
              </p>
            </div>
            <div class="d-flex align-items-center mt-xl-3 mb-3">
              <div class="fs-22 exMedium display-center">
                Automate Listing{" "}
                <div class="toggle-switch form-check form-switch ms-md-5 ms-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="slackToggle"
                    onChange={(e) => {
                      setLoading(true);
                      automateProperty(property)
                    }}
                    checked={property?.autoUpdateStatus ? true : false}
                    disabled={loading}
                  />
                </div>
              </div>
              <div class="vr mx-md-4 mx-2"></div>
              <div class="fs-22 exMedium d-flex align-items-center">
                Sync Now
                {!isLoadings ? (
                  <div
                    onClick={PropertySyncConfirm}
                    class="sync-btn-lg display-center ms-md-4 ms-2"
                  >
                    <img
                      class=" position-relative"
                      src={
                        process.env.PUBLIC_URL + "Assets/images/icons/sync.svg"
                      }
                      alt=""
                    />
                  </div>
                ) : (

                  <div
                    class="sync-btn-lg-1 display-center ms-md-4 ms-2" style={{ filter: "grayscale(100%)", opacity: 0.5 }}
                  >
                    <img
                      class=" position-relative"
                      src={
                        process.env.PUBLIC_URL + "Assets/images/icons/sync.svg"
                      }
                      alt=""
                    />
                  </div>
                )}
              </div>
              <div class="vr mx-md-4 mx-2"></div>
              <div class="fs-22 exMedium d-flex align-items-center">
                Listing Settings
                <Button
                  className="btn btn-lg border-0 display-center" style={{ marginLeft: "10px" }}
                  onClick={() => handleButtonClick(propertyDetail?.data)}
                >
                  <i className="pi pi-cog fs-3 text-navy"></i>
                </Button>
              </div>
              <div class="vr mx-md-4 mx-2"></div>
              <div className=" d-flex align-items-center fs-18 color-black">
                <p className="mb-0 fs-22 exMedium ">Reset</p>{" "}
                <Button
                  icon="pi pi-undo"
                  className="ms-3 rounded-circle text-navy mint-31 fw-bold display-center"
                  rounded
                  outlined
                  severity="secondary"
                  onClick={(e) => ResetConfirm(e)}
                  disabled={isResetLoading}
                />
              </div>
            </div>
          </div>

          {!isLoading ? (
            <div class="img-gallery ">
              {/* 
                        <Galleria
                            ref={galleria}
                            value={property?.listingImages || []}
                            numVisible={7}
                            style={{ maxWidth: '850px' }}
                            activeIndex={activeIndex}
                            onItemChange={(e) => setActiveIndex(e.index)}
                            circular
                            fullScreen
                            showItemNavigators
                            showThumbnails={false}
                            item={itemTemplate}
                            thumbnail={thumbnailTemplate}
                        /> */}
              <div class="row g-3 ">
                <div class="col-6">
                  <img
                    src={imageUrl1}
                    onError={(e) => {
                      e.target.src =
                        process.env.PUBLIC_URL +
                        "/Assets/images/house_placeholder.png"; // replace with a default image
                    }}
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <div class="col-6 ">
                  <div class="row g-3">
                    <div class="col-6 d-md-block d-none">
                      <img
                        src={imageUrl2}
                        onError={(e) => {
                          e.target.src =
                            process.env.PUBLIC_URL +
                            "/Assets/images/house_placeholder.png"; // replace with a default image
                        }}
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                    <div class="col-md-6 col-12">
                      <img
                        src={imageUrl3}
                        onError={(e) => {
                          e.target.src =
                            process.env.PUBLIC_URL +
                            "/Assets/images/house_placeholder.png"; // replace with a default image
                        }}
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                    <div class="col-6 d-md-block d-none">
                      <img
                        src={imageUrl4}
                        onError={(e) => {
                          e.target.src =
                            process.env.PUBLIC_URL +
                            "/Assets/images/house_placeholder.png"; // replace with a default image
                        }}
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="position-relative">
                        <img
                          src={imageUrl5}
                          onError={(e) => {
                            e.target.src =
                              process.env.PUBLIC_URL +
                              "/Assets/images/house_placeholder.png"; // replace with a default image
                          }}
                          class="img-fluid "
                          alt=""
                        />
                        <div class="position-absolute w-100 h-100 top-0 left-0"></div>
                        <span class="position-absolute top-50 start-50 translate-middle display-4 exDemiBold fw-bold text-white">
                          {imageLength > 4 ? `+${imageLength - 4}` : "+0"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "8%",
              }}
            >
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <ProgressSpinner
                  style={{ height: "50px" }}
                  strokeWidth="3"
                  animationDuration="0.8s"
                />
              </div>
            </div>
          )}

          <div class="d-flex align-items-center justify-content-between my-4 flex-wrap">
            <h4 class="exMedium">{property?.name}</h4>
            <div>
              {airbnbListingUrl ? (
                <a
                  style={{ textDecoration: "none" }}
                  href={airbnbListingUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <button class="btn btn-lg button-navy-outline rounded-pill me-3 mb-sm-0 mb-2">
                    Airbnb Listing
                  </button>
                </a>
              ) : (
                <button
                  class="btn btn-lg button-navy-outline rounded-pill me-3 mb-sm-0 mb-2"
                  onClick={goToNotFoundPage}
                >
                  Airbnb Listing
                </button>
              )}

              <a
                style={{ textDecoration: "none" }}
                href="https://www.airbnb.be/performance/conversion/conversion_rate?lid%5B%5D=48619749&ds-start=-57&ds-end=-27"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <button class="btn btn-lg button-navy rounded-pill mb-sm-0 mb-2">
                  Airbnb Performance
                </button>{" "}
              </a>
            </div>
          </div>

          <div class="property-detail_analytics">
            <div class="row gy-4">
              <div className="col-md-4">
                <div className="card border-0 shadow-sm d-flex align-items-center justify-content-evenly flex-row rounded-17 p-3 purple-15">
                  <h3 className="exBold fs-2 mb-0 me-1">
                    ${monthlyRevenue?.totalRevenuData}K
                  </h3>
                  <div>
                    <p className="mb-1 exMedium text-nowrap">
                    This month’s revenue
                    </p>
                    {/* <button className="btn btn-sm rounded-pill me-2 btn-secondary purple-25 text-navy border-0">
                      <img
                        className="me-1"
                        src={
                          process.env.PUBLIC_URL +
                          "/Assets/images/icons/growth.svg"
                        }
                        alt=""
                      />
                      20%
                    </button> */}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card border-0 shadow-sm d-flex align-items-center justify-content-evenly flex-row rounded-17 p-3 lime-20">
                  <h3 className="exBold fs-2 mb-0 me-1">
                    {
                      monthlyRevenue?.bookingPhaseMomentumWeeklyPerticularListing
                    }
                  </h3>
                  <div>
                    <p className="mb-1 exMedium text-nowrap">
                      BookingPace Momentum
                    </p>
                    {/* <button className="btn btn-sm rounded-pill me-2 btn-secondary lime-40 text-navy border-0">
                      <img
                        className="me-1 rotate-180"
                        src={
                          process.env.PUBLIC_URL +
                          "/Assets/images/icons/growth.svg"
                        }
                        alt=""
                      />
                      34%
                    </button> */}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card border-0 shadow-sm d-flex align-items-center justify-content-evenly flex-row rounded-17 p-3 mint-30 h-100">
                  <h3 className="exBold fs-2 mb-0 me-2">
                    {monthlyRevenue?.averageReviewScrore}
                  </h3>
                  <div>
                    <p className="mb-1 exMedium">Average Review Score</p>
                    {/* <div className="rating_star d-flex align-items-center">
                      <InputIcon className="pi pi-star-fill fs-5" />
                      <InputIcon className="pi pi-star-fill fs-5" />
                      <InputIcon className="pi pi-star-fill fs-5" />
                      <InputIcon className="pi pi-star-fill fs-5" />
                      <InputIcon className="pi pi-star fs-5" />
                      <button className="btn btn-sm rounded-pill mx-2 btn-secondary mint-40 text-navy border-0">
                        <img
                          className="me-1"
                          src={
                            process.env.PUBLIC_URL +
                            "/Assets/images/icons/growth.svg"
                          }
                          alt=""
                        />
                        18%
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-8 col-lg-7">
              <div class="mt-4 border rounded-17 p-4">

                {isEditing && (
                  <div class="rounded-17 purple-15 mt-3 p-4">
                    <h4 class="exMedium fs-18">Edit Details</h4>
                    <div class="form-floating mt-3">

                      <h6 class="exMedium">Title:</h6>
                      <textarea
                        class="form-control mb-3"
                        id="title"
                        name="title"
                        value={propertyDetails?.title}
                        onChange={(e) => onTextFieldChange(e)}
                        rows="20"  // Adjust to a more reasonable number
                        cols="60"
                      ></textarea>

                      <h6 class="exMedium">Description Additional:</h6>
                      <textarea
                        class="form-control mb-3"
                        id="descriptionAdditional"
                        name="descriptionAdditional"
                        value={propertyDetails?.descriptionAdditional}
                        onChange={(e) => onTextFieldChange(e)}
                      ></textarea>

                      <h6 class="exMedium">Description Include:</h6>
                      <textarea
                        class="form-control mb-3"
                        id="descriptionInclude"
                        name="descriptionInclude"
                        value={propertyDetails?.descriptionInclude}
                        onChange={(e) => onTextFieldChange(e)}
                      ></textarea>

                      <h6 class="exMedium">Description Exclude:</h6>
                      <textarea
                        class="form-control mb-3"
                        id="descriptionExclude"
                        name="descriptionExclude"
                        value={propertyDetails?.descriptionExclude}
                        onChange={(e) => onTextFieldChange(e)}
                      ></textarea>

                      <h6 class="exMedium mb-2">Bullet Option:</h6>
                      <div className="radio d-inline ms-2 me-4">
                        <label>
                          <input
                            className="me-2"
                            type="radio"
                            id="isBulletYes"
                            name="isBullet"
                            value="yes"
                            defaultChecked={propertyDetails?.bullet_option === 'yes'}
                            onChange={(e) => onTextFieldChange(e)}
                          />
                          Yes
                        </label>
                      </div>
                      <div className="radio d-inline">
                        <label>
                          <input
                            className="me-2"
                            type="radio"
                            id="isBulletNo"
                            name="isBullet"
                            value="no"
                            defaultChecked={propertyDetails?.bullet_option === 'no'}
                            onChange={(e) => onTextFieldChange(e)}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    <div class="text-end ">
                      <button
                        onClick={() => setIsEditing(false)}
                        class="btn btn-purple-outline me-3"
                      >
                        {" "}
                        Cancel
                      </button>
                      <button
                        onClick={() => saveDescription(propertyDetails)}
                        class="btn button-purple"
                      >
                        <img
                          className="me-1"
                          src={
                            process.env.PUBLIC_URL +
                            "Assets/images/icons/save.svg"
                          }
                          alt=""
                        />
                        Save
                      </button>
                    </div>
                  </div>
                )}
                {
                  <>
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="exMedium">Original Description</h4>
                      {
                        !isEditing && (
                          <button
                            onClick={() => setIsEditing(true)}
                            class="btn btn-purple-outline"
                          >
                            <img
                              className="me-2"
                              src={
                                process.env.PUBLIC_URL +
                                "Assets/images/icons/edit.svg"
                              }
                              alt=""
                            />
                            Edit
                          </button>
                        )}
                    </div>
                    {
                      property?.description !== null ? (
                        <div>
                          <p className="text-navy-lightfor my-4" dangerouslySetInnerHTML={{ __html: showFullDescription ? description : getTruncatedDescription(description) }} />
                          {description.split(' ').length > 100 && (
                            <a onClick={handleToggleDescription} className="text-blue-500">
                              {showFullDescription ? 'See Less' : 'See More'}
                            </a>
                          )}
                        </div>
                      ) : (
                        <p className="text-navy-light my-4">No Description Available.</p>
                      )
                    }

                  </>
                }

                {touristAttractionData?.length > 0 ? (
                  <div>
                    <h5 className="fs-18 exMedium mb-3 mt-3">Nearby Tourist Attractions</h5>
                    {touristAttractionData?.map((attraction, index) => (
                      <div key={index} className="d-flex">
                        {" "}
                        &#10148;{" "}
                        <p className="ms-2 text-navy-light">
                          <span className="exMedium color-dark">{attraction?.name?.replace(/\s*\([^)]+\)/, '')} {attraction.name ? `:` : null} </span> {attraction.description}
                          <small className="w-100 color-dark d-flex align-items-center mt-2">
                            <i className="pi pi-map-marker me-2"></i>
                            {attraction?.name?.match(/\(([^)]+)\)/)?.[1]} away
                          </small>
                        </p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    <h5 className="fs-18 exMedium mb-3 mt-3">Nearby Tourist Attractions</h5>
                    <p className="text-navy-light">No nearby tourist attractions found.</p>
                  </div>
                )}

                {
                  eventAttractionData.length > 0 ? (
                    <div>
                      <h5 class="fs-18 exMedium mb-3">Nearby Events</h5>
                      {eventAttractionData.map((event, index) => (
                        <div key={index} class="d-flex">
                          {" "}
                          &#10148;{" "}
                          <p class="ms-2 text-navy-light mb-0">
                            <span class="exMedium color-dark">
                              {event?.name}
                            </span>
                          </p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div>
                      <h5 class="fs-18 exMedium mb-3">Nearby Events</h5>
                      <p class="text-navy-light">No nearby Events found.</p>
                    </div>
                  )
                }
              </div>

              {/* <!-- -------------------------------------Accordions start---------------------------- --> */}
              <div class="accordion" id="accords">


                <div class="accordion-item mt-4">
                  <h2 class="accordion-header" id="updateAccords">
                    <button
                      class="accordion-button collapsed exMedium fs-5"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseUpdate"
                      aria-expanded="false"
                      aria-controls="collapseUpdate"
                    >
                      Update History
                    </button>
                  </h2>
                  <div
                    id="collapseUpdate"
                    class="accordion-collapse collapse"
                    aria-labelledby="updateAccords"
                    data-bs-parent="#accords"
                  >
                    <div class="accordion-body">
                      <DataTable
                        className="shadow-sm rounded-17 my-3 "
                        expandedRows={expandedRows}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        onRowExpand={onRowExpand}
                        onRowCollapse={onRowCollapse}
                        rowExpansionTemplate={rowExpansionTemplate}
                        header={header}
                        showGridlines
                        stripedRows
                        removableSort
                        value={historyDetails}
                        dataKey="id"
                        tableStyle={{ minWidth: "50rem" }}
                        responsiveLayout="scroll"
                        size="small"
                        rows={5}
                        footer={tableFooter}
                        onRowClick={onRowClick}
                      >
                        <Column
                          field="title"
                          header="Title"
                          sortable
                          body={titleBodyTemplate}
                        ></Column>
                        <Column
                          field="date"
                          header="Date"
                          body={dateBodyTemplate}
                        ></Column>

                        <Column
                          field="included_updates"
                          header="Include Aspects"
                          sortable
                          body={includedData}
                        ></Column>

                        <Column
                          field="update_mode"
                          header="Update Mode"
                          sortable
                          body="Automated"
                        ></Column>
                        <Column
                          className=""
                          header="-"
                          expander={allowExpansion}
                        />
                      </DataTable>
                    </div>
                  </div>
                </div>

                <div class="accordion-item mt-4">
                  <h2 class="accordion-header" id="amenitiesAccords">
                    <button
                      class="accordion-button collapsed exMedium fs-5"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseAmenities"
                      aria-expanded="false"
                      aria-controls="collapseAmenities"
                    >
                      Amenities & Features
                    </button>
                  </h2>
                  <div
                    id="collapseAmenities"
                    class="accordion-collapse collapse"
                    aria-labelledby="amenitiesAccords"
                    data-bs-parent="#accords"
                  >
                    <div class="accordion-body">
                      <div class="row mb-4 gy-3 flex-wrap">
                        <h6 class="fs-18 exMedium">Schenic views</h6>
                        <div class="col-md-4 d-flex align-items-center">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          River views
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Lush green garden
                        </div>
                      </div>
                      <div class="row mb-4 gy-3 flex-wrap">
                        <h6 class="fs-18 exMedium">Bedroom and bathroom</h6>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Essentials
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Hangers
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Bed linen
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Extra pillows and blankets
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Hair dryer
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Hot water
                        </div>
                      </div>
                      <div class="row mb-4 gy-3 flex-wrap">
                        <h6 class="fs-18 exMedium">Work and entertainment</h6>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Ethernet connection
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          34-inch HDTV with Netflix
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Pocket wifi
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Wifi
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Dedicated workspace
                        </div>
                      </div>
                      <div class="row mb-4 gy-3 flex-wrap">
                        <h6 class="fs-18 exMedium">Home safety</h6>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Exterior security cameras
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Smoke alarm
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          First aid kit
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Fire extinguisher
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Carbon monoxide alarm
                        </div>
                      </div>
                      <div class="row mb-4 gy-3 flex-wrap">
                        <h6 class="fs-18 exMedium">Kitchen and dining</h6>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Toaster
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Cooking basics
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Fridge
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Dishes and cutlery
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Coffee maker
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Oven
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          LG electric cooker
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Wine glasses
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Kettle
                        </div>
                      </div>
                      <div class="row mb-4 gy-3 flex-wrap">
                        <h6 class="fs-18 exMedium">Outdoor</h6>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Private patio or balcony
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Private back garden – Fully fenced
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Outdoor furniture
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Free parking on premises
                        </div>
                      </div>
                      <div class="row mb-4 gy-3 flex-wrap">
                        <h6 class="fs-18 exMedium">Services</h6>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Self check-in
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Lockbox
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Building staff
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Free parking on premises
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Pets allowed
                        </div>
                      </div>
                      <div class="row mb-4 gy-3 flex-wrap">
                        <h6 class="fs-18 exMedium">Heating and cooling</h6>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Central air conditioning
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Indoor fireplace: wood-burning
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Ceiling fan
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Heating
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Ceiling fan
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mt-4">
                  <h2 class="accordion-header" id="additionalAccords">
                    <button
                      class="accordion-button collapsed exMedium fs-5"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseAdditional"
                      aria-expanded="false"
                      aria-controls="collapseAdditional"
                    >
                      Additional Info
                    </button>
                  </h2>
                  <div
                    id="collapseAdditional"
                    class="accordion-collapse collapse"
                    aria-labelledby="additionalAccords"
                    data-bs-parent="#accords"
                  >
                    <div class="accordion-body">
                      <div class="row mb-4 gy-3 flex-wrap">
                        <h6 class="fs-18 exMedium">What this place offers</h6>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Name: {propertyDetail?.data?.hostawayResultData?.name || "N/A"}
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Person Capacity: {propertyDetail?.data?.hostawayResultData?.personCapacity || "N/A"}
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Room Type: {propertyDetail?.data?.hostawayResultData?.roomType || "N/A"}
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Bathroom Type: {propertyDetail?.data?.hostawayResultData?.bathroomType || "N/A"}
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Guests Included: {propertyDetail?.data?.hostawayResultData?.guestsIncluded || "N/A"}
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Min Nights: {propertyDetail?.data?.hostawayResultData?.minNights || "N/A"}
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Max Nights: {propertyDetail?.data?.hostawayResultData?.maxNights || "N/A"}
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Cancellation Policy: {propertyDetail?.data?.hostawayResultData?.cancellationPolicy || "N/A"}
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Cleaning Fee: $ {propertyDetail?.data?.hostawayResultData?.cleaningFee || 0}
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          CheckIn TimeStart: {propertyDetail?.data?.hostawayResultData?.checkInTimeStart || "N/A"}
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          CheckIn TimeEnd: {propertyDetail?.data?.hostawayResultData?.checkInTimeEnd || "N/A"}
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          CheckOut Time: {propertyDetail?.data?.hostawayResultData?.checkOutTime || "N/A"}
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Checkin Fee: $ {propertyDetail?.data?.hostawayResultData?.checkinFee || 0}
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Weekly Discount: {propertyDetail?.data?.hostawayResultData?.weeklyDiscount || "N/A"}
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Time Zone: {propertyDetail?.data?.hostawayResultData?.timeZoneName || "N/A"}
                        </div>
                      </div>
                      <div class="row mb-4 gy-3 flex-wrap">
                        <h6 class="fs-18 exMedium">Address</h6>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Country: {propertyDetail?.data?.hostawayResultData?.country || "N/A"}
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          State: {propertyDetail?.data?.hostawayResultData?.state || "N/A"}
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          City: {propertyDetail?.data?.hostawayResultData?.city || "N/A"}
                        </div>
                        <div class="col-md-4 d-flex align-items-center ">
                          <span>
                            <i className="pi pi-circle-fill" style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                          </span>
                          Zip Code: {propertyDetail?.data?.hostawayResultData?.zipcode || "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              {/* <!-- -------------------------------------Accordions end---------------------------- --> */}

              <div class="border rounded-17 mt-4 p-4">
                <div className="d-flex justify-content-between">
                  <h5 class="exMedium"> Location</h5>
                  <h5 class="exMedium">
                    <i2 className="pi pi-circle-fill icon-style"></i2>
                    Current Property &nbsp;&nbsp;&nbsp;
                    <i2 className="pi pi-circle-fill icon-style2"></i2>
                    Activated Property
                  </h5>
                </div>
                <MapComponent recordId={userROWID} />
              </div>
            </div>
            <div class="col-xl-4 col-lg-5">
              <aside class="aside_section sticky-top">
                <div class="border rounded-17 mt-4 ">
                  <div class="d-flex align-items-center justify-content-between p-4">
                    <h4 class="exMedium mb-0">Local Selling Points</h4>
                    {
                      selectedTouristAttraction.length > 0 || Object.keys(eventAttraction).length > 0 ?
                        <button class="btn button-purple" onClick={() => addEvents(userROWID)}>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <mask
                              id="mask0_482_1707"
                              style={{ maskType: "alpha" }}
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            >
                              <rect width="24" height="24" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_482_1707)">
                              <path
                                d="M11 13H5V11H11V5H13V11H19V13H13V19H11V13Z"
                                fill="#ffffff"
                              />
                            </g>
                          </svg>
                          Add
                        </button> : ""
                    }
                  </div>
                  <hr class="mx-4 mt-2 mb-4" />

                  {/* <!-- -------------------------------------------------Nearby tourist carousel start-------------------------------- --> */}

                  <div
                    id="attractionCarousel"
                    class="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div class="d-flex align-items-center justify-content-between px-4">
                      <h5 class="exMedium mb-0">Nearby Tourist Attractions</h5>
                      <div class="d-flex justify-content-end">
                        <button
                          class="carousel-control-prev rounded-circle position-static me-3"
                          type="button"
                          data-bs-target="#attractionCarousel"
                          data-bs-slide="prev"
                        >
                          <span class=" fs-1 color-dark" aria-hidden="true">
                            &#8249;{" "}
                          </span>
                          <span class="visually-hidden">Previous</span>
                        </button>
                        <button
                          class="carousel-control-next rounded-circle position-static"
                          type="button"
                          data-bs-target="#attractionCarousel"
                          data-bs-slide="next"
                        >
                          <span class=" fs-1 color-dark" aria-hidden="true">
                            &#8250;{" "}
                          </span>
                          <span class="visually-hidden">Next</span>
                        </button>
                      </div>
                    </div>
                    <div class="carousel-inner p-4 pb-2">
                      {attractions?.length > 0 ? (
                        attractions?.map((attractionsItem, index) => (
                          <div
                            class={`carousel-item ${index === 0 ? "active" : ""}`}
                            key={index}
                          >
                            <div class="card mb-3 rounded-2 ">
                              <div class="row g-0">
                                <div class="col-4 ps-3 py-3">
                                  <img
                                    src={attractionsItem?.thumnailUrl}
                                    class="attractImg img-fluid rounded-start rounded-2"
                                    alt=""
                                  />
                                </div>
                                <div class="col-8">
                                  <div class="card-body">
                                    <h5 class="card-title exMedium color-black ">
                                      {attractionsItem?.name}
                                    </h5>
                                    <p class="card-text mb-5">
                                      {attractionsItem?.description}
                                    </p>          <div className="position-absolute end-0 bottom-0 p-3">
                                      <input
                                        type="checkbox"
                                        id={`checkbox-${index}`}
                                        value={attractionsItem?.name}
                                        checked={selectedTouristAttraction?.some((attraction) => attraction.name === attractionsItem.name)}
                                        onChange={(e) =>
                                          handleCheckbox(e, attractionsItem)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div class="carousel-item active">
                          <div class="card mb-3 rounded-2">
                            <div class="card-body">
                              <h5 class="card-title exMedium color-black">No tourist attractions found.</h5>
                              <p class="card-text">Please try searching again or check back later.</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* <!-- -------------------------------------------------Nearby tourist carousel end-------------------------------- -->

            <!-- -------------------------------------------------Nearby events carousel start-------------------------------- --> */}

                  <hr class="mx-4 mb-4" />
                  <div
                    id="eventCarousel"
                    class="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div class="d-flex align-items-center justify-content-between px-4">
                      <h5 class="exMedium mb-0">Nearby Events</h5>
                      <div class="d-flex justify-content-end">
                        <button
                          class="carousel-control-prev rounded-circle position-static me-3"
                          type="button"
                          data-bs-target="#eventCarousel"
                          data-bs-slide="prev"
                        >
                          <span class="fs-1 color-dark" aria-hidden="true">
                            &#8249;
                          </span>
                          <span class="visually-hidden">Previous</span>
                        </button>
                        <button
                          class="carousel-control-next rounded-circle position-static"
                          type="button"
                          data-bs-target="#eventCarousel"
                          data-bs-slide="next"
                        >
                          <span class="fs-1 color-dark" aria-hidden="true">
                            &#8250;
                          </span>
                          <span class="visually-hidden">Next</span>
                        </button>
                      </div>
                    </div>
                    <div class="carousel-inner p-4 pb-1">
                      {event?.length > 0 ? (
                        event?.map((eventItem, index) => (
                          <div
                            class={`carousel-item ${index === 0 ? "active" : ""}`}
                            key={index}
                          >
                            <div class="card mb-3 rounded-2">
                              <div class="row g-0">
                                <div class="col-4 ps-3 py-3">
                                  <img
                                    src={eventItem?.eventImage?.url}
                                    class="evenImag img-fluid rounded-start rounded-2"
                                    alt="..."
                                  />
                                  {/* <div class="d-flex mt-2">
                <span class="ms-1">197</span>
              </div> */}
                                </div>
                                <div class="col-8">
                                  <div class="card-body h-100">
                                    <h5 class="card-title exMedium color-black mb-5">
                                      {eventItem?.eventName}
                                    </h5>
                                    {/* <p class="card-subtitle mb-1">
                  (Historical Tours)
                </p> */}
                                    {/* <p class="card-text">
                  The Neil Diamond Musical on Broadway Ticket
                </p> */}
                                    <div className="position-absolute end-0 bottom-0 p-3 ">
                                      <div className="d-flex align-items-center justify-content-end h-100 ">
                                        <p class="card-text mb-0">
                                          $
                                          {eventItem?.eventPriceRange[0]?.min || 0}{" "}
                                          &nbsp;
                                        </p>
                                        <p class="card-text mb-0 d-flex align-items-center">
                                          - $
                                          {eventItem?.eventPriceRange[0]?.max || 0}{" "}
                                          <input
                                            class="form-check-input ms-2 mt-0"
                                            type="checkbox"
                                            id={`checkbox-${index}`}
                                            value={eventItem.eventName}
                                            checked={selectedEvent === eventItem.eventName}
                                            onChange={(e) =>
                                              handleEventCheckbox(e, eventItem)
                                            }
                                          />
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div class="carousel-item active">
                          <div class="card mb-3 rounded-2">
                            <div class="card-body">
                              <h5 class="card-title exMedium color-black">No Events found.</h5>
                              <p class="card-text">Please try searching again or check back later.</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* <!-- -------------------------------------------------Nearby events carousel end-------------------------------- --> */}
                </div>
                <div class="border rounded-17 mt-4 p-4">
                  <h5 class="exMedium">{propertyDetail?.data?.name}</h5>
                  <p class="exMedium">
                    Listing ID:{" "}
                    <span class="exLight">{userROWID}</span>
                  </p>
                  <div>
                    {propertyDetail?.data?.dataSource == "hostaway" ? (
                      <button class="btn rounded-pill purple-15 me-3 exMono tags my-2" style={{ cursor: "auto" }}>
                        Hostaway{" "}
                      </button>
                    ) : (
                      <>
                        <button class="btn rounded-pill lime-30 me-3 exMono tags my-2" style={{ cursor: "auto" }}>
                          Guesty{" "}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </aside>
            </div>
          </div>
          <ConfirmDialog className="toggleOffAlert" />

        </div>
      </section>

      <SettingDialog
        visible={visible}
        setVisible={setVisible}
        propertyDetail={propertyDetail?.data}
      />
    </>
  );
}

export default PropertyDetail;
