import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { includeExcludeUpdate } from "../../Redux/Features/authReducer/authSlice";
import { getPropertyById } from "../../Redux/Features/listReducer/listSlice";
import { SelectButton } from 'primereact/selectbutton';

function SettingDialog(props) {
  const { visible, setVisible, propertyDetail } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const options = ['On', 'Off'];
    const [value, setValue] = useState(options[0]);

  const handleOnChange = (e, value) => {
    setLoading(true);
    dispatch(
      includeExcludeUpdate({
        recordId: propertyDetail?.recordId,
        [e.target.name]: value || e.target.value,
      })
    )
      .unwrap()
      .then((result) => {
        dispatch(
          getPropertyById({
            recordId: propertyDetail?.recordId,
          })
        ).unwrap();
        setLoading(false);
      });
  };

  const settingHeader = (
    <>
      <h4 className="color-black exMedium mb-3">Listing Settings</h4>
    </>
  );

  return (
    <>
      <Dialog
        className="p-4 background-white rounded-17 mx-3"
        header={settingHeader}
        visible={visible}
        style={{ maxWidth: "400px", width: "100%" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <div>
          <div className="d-flex align-items-center justify-content-between border-bottom py-3">
            <label htmlFor="switch1">Description Space</label>
            <select
              className="form-select btn-outline border-secondary rounded-pill w-auto pe-40"
              aria-label="Select option"
              name="format"
              onChange={(e) => handleOnChange(e)}
            >
              <option value={1} selected={(propertyDetail?.format) == 1}>
                1
              </option>
              <option value={2} selected={(propertyDetail?.format) == 2}>
                2
              </option>
            </select>
          </div>
          <div className="d-flex align-items-center justify-content-between border-bottom py-3">
            <label htmlFor="switch1">IsSpace</label>
            {/* <InputSwitch
              onIcon="pi pi-check"
              offIcon="pi pi-times"
              className="w-8rem ms-3"
              name="isSpace"
              checked={propertyDetail?.isSpace === "yes" ? true : false}
              onChange={(e) => {
                setLoading(true);
                handleOnChange(
                  e,
                  propertyDetail?.isSpace === "yes" ? "no" : "yes"
                )
              }}
              disabled={loading}
            />        */}

            <SelectButton value={value} onChange={(e) => setValue(e.value)} options={options} />

          </div>
          <div className="d-flex align-items-center justify-content-between border-bottom py-3">
            <label htmlFor="switch1">IsTitle</label>
            <InputSwitch
              onIcon="pi pi-check"
              offIcon="pi pi-times"
              className="w-8rem ms-3"
              name="isTitle"
              checked={propertyDetail?.isTitle === "yes" ? true : false}
              onChange={(e) => {
                setLoading(true);
                handleOnChange(
                  e,
                  propertyDetail?.isTitle === "yes" ? "no" : "yes"
                )
              }}
              disabled={loading}
            />
          </div>
          <div className="d-flex align-items-center justify-content-between border-bottom py-3">
            <label htmlFor="switch1">IsSummary</label>
            <InputSwitch
              onIcon="pi pi-check"
              offIcon="pi pi-times"
              className="w-8rem ms-3"
              name="isSummary"
              checked={propertyDetail?.isSummary === "yes" ? true : false}
              onChange={(e) => {
                setLoading(true);
                handleOnChange(
                  e,
                  propertyDetail?.isSummary === "yes" ? "no" : "yes"
                )
              }}
              disabled={loading}
            />
          </div>

          <div className="d-flex align-items-center justify-content-between  py-3">
            <label htmlFor="switch1">Bullet Points</label>
            <InputSwitch
              onIcon="pi pi-check"
              offIcon="pi pi-times"
              className="w-8rem ms-3"
              name="bullet_option"
              checked={propertyDetail?.bullet_option === "yes" ? true : false}
              onChange={(e) => {
                setLoading(true);
                handleOnChange(e, propertyDetail?.bullet_option === "yes" ? "no" : "yes")
              }}
              disabled={loading}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default SettingDialog;
