import React, { useEffect, useState, useRef } from "react";
import { PAYMENT_URL } from "../../Config/Config";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Header from "../../Layouts/HeaderPage";
import SideBar from "../../Layouts/SideBar";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputSwitch } from "primereact/inputswitch";
import { Field, Form, Formik } from "formik";
import { propertyFilterSchema } from "../../Utils/Validation/Validation";
import { getToken2 } from "../../Redux/Features/LocalStorage";
import {
  getPropertyById,
  getCountries,
  getCity,
  gettags,
  autoUnautoPropertyListing,
} from "../../Redux/Features/listReducer/listSlice";
import SettingDialog from "../../Components/PropertyListing/SettingDialog";
import {
  HostawayLIstings,
  manualUpdateListing,
  autoUpdateListing,
  inactiveHostawayListing,
  includeExcludeUpdate,
  resetSingleAndMultipleProperty
} from "../../Redux/Features/authReducer/authSlice";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { FilterMatchMode } from "primereact/api";
import { DateFormat } from "../../Utils/constant/commonFunction";
import { ProgressSpinner } from "primereact/progressspinner";
import { OverlayPanel } from 'primereact/overlaypanel';
import SubscriptionModal from "../SubscriptionModal"
import {
  setPaymentId,
  removePaymentId,
} from "../../Redux/Features/LocalStorage";

function PropertyListing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [listData, setListData] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isResetLoading, setIsResetLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(true);
  const [isShow, setIsShow] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [disable, setDisable] = useState(false);
  const [isProgressBar, setIsProgressBar] = useState(false);

  const token2 = getToken2()
  const op = useRef(null);
  const user = localStorage.getItem("ImpersonateUser");

  const {
    HostawayLIstingsData,
    manualUpdateListingData,
    autoUpdateListingData,
  } = useSelector((state) => state.Auth);
  const { getCountriesData, getCityData, gettagsData } = useSelector((state) => state.List);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    if (selectedProperties?.length > 0) {
      const data = selectedProperties.every((ind) => ind.autoUpdateStatus == 1);
      if (data) {
        setChecked(true);
      } else {
        setChecked(false);
      }
    }
  }, [selectedProperties]);

  // Define initial values to filter the properies
  const initialValues = {
    propertyStatus: "all",
    dataSource: "all",
    country: "",
    city: "",
  };

  useEffect(() => {
    if (getCountriesData?.countryData?.length > 0) {
      setCountryData(getCountriesData?.countryData);
    }
  }, [getCountriesData?.countryData]);

  useEffect(() => {
    if (selectedProperties?.length > 0) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
  }, [selectedProperties?.length]);

  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setIsSpinner(false);
    }, 2000);
    return () => {
      clearTimeout(loadingTimer);
    };
  }, []);

  useEffect(() => {
    dispatch(HostawayLIstings());
    dispatch(getCountries());
    dispatch(gettags());
  }, []);

  useEffect(() => {
    if (HostawayLIstingsData !== "") {
      setListData(
        HostawayLIstingsData?.data.filter((elem) => elem.status === "active")
      );
    }
  }, [HostawayLIstingsData]);

  useEffect(() => {
    dispatch(HostawayLIstings());
  }, [
    manualUpdateListingData,
    autoUpdateListingData,
    autoUnautoPropertyListing,
    token2
  ]);

  const syncListRecordManually = async (row) => {
    removePaymentId();
    try {
      if (row?.recordId) {
        setIsLoading(true);
        setIsProgressBar(true);
        const { data } = await dispatch(
          manualUpdateListing({
            recordId: [row?.recordId],
            redirectUrl: PAYMENT_URL
          })
        ).unwrap();
        await dispatch(HostawayLIstings()).unwrap();
        setIsLoading(false);
        setIsProgressBar(false);
        if (data?.checkoutUrl) {
          setPaymentId(data?.id);
          window.location.href = data?.checkoutUrl;
        } else {
          console.error("Checkout URL is missing");
          setIsLoading(false);
          setIsProgressBar(false);
        }
      }
    }
    catch (error) {
      console.log(error);
      setIsLoading(false);
      setIsProgressBar(false);

    }
  };

  const onSelectedProperties = (e) => {
    setSelectedProperties(e.value);
  };

  const automateProperty = async (isEnable, row) => {
    removePaymentId();
    if (isEnable) {
      setIsLoading(true);
      const { data } = await dispatch(
        autoUpdateListing({
          primaryPropertyId: row?.id,
          redirectUrl: PAYMENT_URL
        })
      ).unwrap();
      await dispatch(HostawayLIstings()).unwrap();
      setIsLoading(false);
      if (data?.checkoutUrl) {
        setPaymentId(data?.id);
        window.location.href = data?.checkoutUrl;
      } else {
        console.error("Checkout URL is missing");
      }
    } else {
      confirm(row);
    }
  };

  const handleAutomate = async (row) => {
    setDisable(true);
    removePaymentId();
    const unAutomatedList = selectedProperties.filter(
      (item) => !item.autoUpdateStatus
    );
    if (selectedProperties.length === 0) {
      setChecked(false);
      return;
    }

    if (unAutomatedList.length > 0) {
      const { data } = await dispatch(
        autoUnautoPropertyListing({
          propertyListingIds: unAutomatedList.map((item) => item?.id),
          propertyFlag: "allautomated",
          redirectUrl: PAYMENT_URL
        })
      ).unwrap();
      await dispatch(HostawayLIstings()).unwrap();
      setIsLoading(false);
      setDisable(false);
      if (data?.checkoutUrl) {
        setPaymentId(data?.id);
        window.location.href = data?.checkoutUrl;
      } else {
        console.error("Checkout URL is missing");
        setDisable(false);
      }
    } else {
      globalconfirm(row);
    }

    setChecked(false);
    setSelectedProperties([]);
  };

  const handleSyncNow = async () => {
    removePaymentId();
    try {
      if (selectedProperties.map((item) => item?.recordId)) {
        setIsLoading(true);
        setIsProgressBar(true);
        const { data } = await dispatch(
          manualUpdateListing({
            recordId: selectedProperties.map((item) => item?.recordId),
            redirectUrl: PAYMENT_URL
          })
        )
          .unwrap();
        await dispatch(HostawayLIstings()).unwrap();
        setIsLoading(false);
        setIsProgressBar(false);
        if (data?.checkoutUrl) {
          setPaymentId(data?.id);
          window.location.href = data?.checkoutUrl;
        } else {
          console.error("Checkout URL is missing");
        }
        setChecked(false);
        setSelectedProperties([]);

      }
    }
    catch (error) {
      console.log(error)
      setIsLoading(false);
      setIsProgressBar(false);
    }
  };

  const handleDelete = () => {
    dispatch(
      inactiveHostawayListing({
        inactiveDataList: selectedProperties.map((item) => item.id),
      })
    )
      .unwrap()
      .then((result) => {
        dispatch(HostawayLIstings());
      })
      .catch((error) => {
        console.log(error);
      });
    setChecked(false);
    setSelectedProperties([]);

  };
  const GlobalDeleteRejectBullet = () => { };

  const onApplyFilter = (values, { resetForm }) => {
    const payload = Object?.entries(values)
      ?.filter(([key, value]) => value !== "")
      ?.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    dispatch(HostawayLIstings(payload));
    resetForm();
  };

  const GlobalSyncConfirm = (row) => {

    confirmDialog({
      message: (
        <div className="mx-3 my-2 text-center">
          <h5 className="exDemiBold fs-2 text-center text-navy my-2">  <i className="pi pi-exclamation-triangle"></i> &nbsp; Are you sure?</h5>
          <p className="text-navy-light text-center w-75 m-auto">
            Sync process is valid for only one day.
          </p>
        </div>

      ),
      closable: false, // Add this line to prevent closing the dialog
      // dismissableMask: true, // Allow clicking outside to close
      defaultFocus: "accept",
      accept: () => handleSyncNow(row),
      reject: () => GlobalSyncRejectBullet(row),
    });
  };
  const GlobalSyncRejectBullet = () => { };

  const SyncConfirm = (row) => {
    confirmDialog({
      message: (
        <div className="mx-3 my-2 text-center">
          <h5 className="exDemiBold fs-2 text-center text-navy my-2">  <i className="pi pi-exclamation-triangle"></i> &nbsp; Are you sure?</h5>
          <p className="text-navy-light text-center w-75 m-auto">
            Sync process is valid for only one day.
          </p>
        </div>

      ),
      closable: false, // Add this line to prevent closing the dialog
      // dismissableMask: true, // Allow clicking outside to close

      defaultFocus: "accept",
      accept: () => syncListRecordManually(row),
      reject: () => SyncRejectBullet(row),
    });

  };
  const SyncRejectBullet = () => { };

  const handleEditSection = (data) => {
    localStorage.setItem('EditdID', (data)); // Store data as a string
    navigate("/propertydetails");
  };

  const RestoreConfirm = (row) => {
    confirmDialog({
      message: (
        <div className="mx-3 my-2 text-center">
          <h5 className="exDemiBold fs-2 text-center text-navy my-2">
            <i className="pi pi-exclamation-triangle"></i> &nbsp; Are you sure?</h5>
          <p className="text-navy-light text-center w-75 m-auto">
            Do you want to reset your property listing details?
          </p>
        </div>
      ),
      closable: false, // Add this line to prevent closing the dialog
      defaultFocus: "accept",
      accept: () => restoreRecordManually(row),
      reject: () => RestorRejectBullet(row),
    });

  };

  const restoreRecordManually = async (row) => {
    try {
      if (row?.recordId) {
        setIsResetLoading(true);
        await dispatch(
          resetSingleAndMultipleProperty({
            primaryIds: [row?.recordId]
          }))
          .unwrap();
        setIsResetLoading(false);
      }
    }
    catch (error) {
      console.log(error);
      setIsResetLoading(false);
    }
  };
  const RestorRejectBullet = () => { };

  const GlobalDeleteConfirm = (row) => {
    confirmDialog({
      message: (
        <div className="mx-3 my-2 text-center">
          <h5 className="exDemiBold fs-2 text-center text-navy my-2">
            <i className="pi pi-info-circle" style={{ color: "rgb(241, 36, 36)" }}></i> &nbsp; Are you sure?</h5>
          <p className="text-navy-light text-center w-75 m-auto">
            Do you want to delete these property listings?
          </p>
        </div>

      ),
      closable: false, // Add this line to prevent closing the dialog
      defaultFocus: "accept",
      accept: () => handleDelete(),
      reject: () => GlobalDeleteRejectBullet(),
    });

  };

  const GlobalRestoreConfirm = (row) => {
    confirmDialog({
      message: (
        <div className="mx-3 my-2 text-center">
          <h5 className="exDemiBold fs-2 text-center text-navy my-2">
            <i className="pi pi-exclamation-triangle"></i> &nbsp; Are you sure?</h5>
          <p className="text-navy-light text-center w-75 m-auto">
            Do you want to reset your property listing details?
          </p>
        </div>

      ),
      closable: false, // Add this line to prevent closing the dialog
      defaultFocus: "accept",
      accept: () => handleRestoreNow(),
      reject: () => GlobalRestoreRejectBullet(),
    });

  };

  const handleRestoreNow = async () => {
    try {
      if (selectedProperties.map((item) => item?.recordId)) {
        setIsResetLoading(true);
        await dispatch(
          resetSingleAndMultipleProperty({
            primaryIds: selectedProperties.map((item) => item?.recordId)

          })
        )
          .unwrap();
        setIsResetLoading(false);
      }
      setChecked(false);
      setSelectedProperties([]);
    }
    catch (error) {
      console.log(error)
      setIsResetLoading(false);
      setChecked(false);
      setSelectedProperties([]);
    }
  };
  const GlobalRestoreRejectBullet = () => { };

  const confirm = (row) => {
    confirmDialog({
      header: (
        <img
          className="propertyImg  me-2"
          src={
            row?.thumbnailUrl ||
            process.env.PUBLIC_URL + "/Assets/images/house_placeholder.png"
          }
          onError={(e) => {
            e.target.src =
              process.env.PUBLIC_URL + "/Assets/images/house_placeholder.png"; // replace with a default image
          }}
          alt="No Image"
        />
      ),
      message: (
        <div className="mx-3 my-2 text-center">
          <h6 className="fs-18 text-navy mt-3 exMedium">
            {row.name}
          </h6>
          <h5 className="exDemiBold fs-2 text-center text-navy my-2">
            <i className="pi pi-exclamation-triangle"></i> &nbsp; Are you sure?</h5>
          <p className="text-navy-light text-center w-75 m-auto">
            If you disable this listing, you will not be able to see future
            updates.
          </p>
        </div>
      ),
      closable: false, // Add this line to prevent closing the dialog
      defaultFocus: "accept",
      accept: () => acceptBullet(row),
      reject: () => rejectBullet(row),
    });
  };

  const acceptBullet = (row) => {
    setDisable(true)
    dispatch(
      autoUpdateListing({
        primaryPropertyId: row?.id,
      })
    )
      .unwrap()
      .then((result) => {
        dispatch(HostawayLIstings())
          .unwrap();
        setDisable(false)
      })
      .catch((error) => {
        console.log(error);
        setDisable(false)
      });
  };

  const rejectBullet = (row) => { };

  const globalconfirm = (row) => {
    confirmDialog({
      header: (
        <img
          className="propertyImg  me-2"
          src={
            row?.thumbnailUrl ||
            process.env.PUBLIC_URL + "/Assets/images/house_placeholder.png"
          }
          onError={(e) => {
            e.target.src =
              process.env.PUBLIC_URL + "/Assets/images/house_placeholder.png"; // replace with a default image
          }}
          alt="No Image"
        />
      ),
      message: (
        <div className="mx-3 my-2 text-center">
          <h5 className="exDemiBold fs-2 text-center text-navy my-2">
            <i className="pi pi-exclamation-triangle"></i> &nbsp; Are you sure?</h5>
          <p className="text-navy-light text-center w-75 m-auto">
            If you disable these listing, you will not be able to see future
            updates.
          </p>
        </div>
      ),
      closable: false, // Add this line to prevent closing the dialog
      defaultFocus: "accept",
      accept: () => globalAcceptBullet(),
      reject: () => globalRejectBullet(),
    });
  };

  const globalAcceptBullet = () => {
    setDisable(true);
    const automatedList = selectedProperties.filter(
      (item) => item.autoUpdateStatus
    );
    dispatch(
      autoUnautoPropertyListing({
        propertyListingIds: automatedList.map((item) => item?.id),
        propertyFlag: "allunautomated",
        redirectUrl: `https://google.com`,
      })
    )
      .unwrap()
      .then((result) => {
        dispatch(HostawayLIstings())
          .unwrap();
        setDisable(false);
      })

      .catch((error) => {
        console.log(error);
        setDisable(false);
      });
  };
  const globalRejectBullet = () => { };

  const handleCity = (selectedCountry) => {
    if (selectedCountry !== "") {
      dispatch(getCity({ country: selectedCountry }))
    }
  };

  const renderHeader = () => {
    return (
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Searchh"
          />
        </IconField>
        <div className="dropdown ms-3 me-auto mb-md-0 mb-2">
          <Button
            icon="pi pi-sliders-h"
            className="filter-btn rounded-pill px-3 py-2 "
            label="Filter"
            type="button"
            id="filterDrop"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            outlined
            severity="secondary"
          />
          <ul class="dropdown-menu mt-2 p-3" aria-labelledby="filterDrop">
            <Formik
              initialValues={initialValues}
              validationSchema={propertyFilterSchema}
              onSubmit={onApplyFilter}
            >
              {(props) => {
                return (
                  <Form>
                    <li>
                      <p className="mb-1  ps-2">Property status</p>
                      <Field
                        as="select"
                        name="propertyStatus"
                        class="form-select rounded-pill"
                        aria-label="Default select example"
                      >
                        <option selected>All</option>
                        <option value={1}>Automated</option>
                        <option value={0}>Unautomated</option>
                      </Field>
                    </li>
                    <li>
                      <p className="mb-1 mt-3 ps-2">Property tags</p>
                      <Field
                        as="select"
                        name="dataSource"
                        class="form-select rounded-pill"
                        aria-label="Default select example"
                      >
                        <option selected>All</option>
                        {gettagsData?.Data?.map((item, index) => (
                          <option key={index} value={item.dataSource}>
                            {item.dataSource}
                          </option>
                        )
                        )}
                      </Field>
                    </li>
                    <li>
                      <p className="mb-1 mt-3 ps-2">Location</p>
                      <div className="d-flex">
                        <Field
                          as="select"
                          name="country"
                          class="form-select rounded-pill me-1"
                          aria-label="Default select example"
                          onChange={(e) => {
                            handleCity(e.target.value);
                            props.setFieldValue("country", e.target.value);
                          }}
                        >
                          <option selected>Country</option>
                          {countryData.map((item, index) => (
                            <option key={index} value={item} >
                              {item}
                            </option>
                          ))}
                        </Field>

                        <Field
                          as="select"
                          name="city"
                          class="form-select rounded-pill ms-1"
                          aria-label="Default select example"
                        >
                          <option selected>City</option>
                          {getCityData?.Data?.map((item, index) => (
                            <option key={index} value={item.city}>
                              {item.city}
                            </option>
                          )
                          )}
                        </Field>
                      </div>
                    </li>

                    <Button
                      type="submit"
                      label="Apply"
                      className="btn btn-sm rounded-pill button-navy mt-3 float-end"
                      rounded
                    ></Button>
                  </Form>
                )
              }}
            </Formik>
          </ul>
        </div>

        {isShow ? (
          <div className="action-buttons d-flex align-items-center justify-content-between me-3 ms-md-3 mb-sm-2">
            <div className=" display-center fs-18 color-black">
              <p className="mb-0">Automate</p>
              <InputSwitch
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                className="w-8rem ms-3"
                checked={checked}
                onChange={(e) => setChecked(e.value)}
                onClick={(e) => {
                  setDisable(true);
                  handleAutomate(e).finally(() => setDisable(false));
                }}
                disabled={disable}
              />
            </div>
            <div className="vr ms-md-4 ms-2 me-md-5 me-2"></div>
            <div className=" d-flex align-items-center fs-18 color-black">
              <p className="mb-0">Sync Now</p>{" "}
              <Button
                icon="pi pi-sync"
                className="ms-3 rounded-circle text-navy mint-30 fw-bold"
                rounded
                outlined
                severity="secondary"
                onClick={(e) => GlobalSyncConfirm(e)}
                disabled={isLoading}
              />
            </div>
            <div className="vr ms-md-4 ms-2 me-md-5 me-2"></div>
            <div className=" d-flex align-items-center fs-18 color-black">
              <p className="mb-0">Delete</p>{" "}
              <Button
                icon="pi pi-trash"
                className="ms-3 rounded-circle text-navy red-10 fw-bold"
                rounded
                outlined
                severity="secondary"
                onClick={(e) => GlobalDeleteConfirm(e)}
              />
            </div>

            <div className="vr ms-md-4 ms-2 me-md-5 me-2"></div>
            <div className=" d-flex align-items-center fs-18 color-black">
              <p className="mb-0">Reset</p>{" "}
              <Button
                icon="pi pi-undo"
                className="ms-3 rounded-circle text-navy mint-30 fw-bold"
                rounded
                outlined
                severity="secondary"
                onClick={(e) => GlobalRestoreConfirm(e)}
                disabled={isResetLoading}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const ActionBodyTemplate = (rowData) => {
    const [visible, setVisible] = useState(false);
    const { propertyDetail } = useSelector((state) => state.List);
    const handleButtonClick = (row) => {
      setVisible(true);
      dispatch(getPropertyById({ recordId: row.recordId }));
    };

    return (
      <React.Fragment>
        <div className="display-center act_btn">
          <Button
            className="rounded-circle"
            icon="pi pi-pen-to-square"
            tooltip="Edit"
            tooltipOptions={{ position: "top" }}
            rounded
            outlined
            severity="secondary"
            onClick={() => handleEditSection(rowData?.recordId)}
          />
          <Button
            className="rounded-circle"
            icon="pi pi-sync"
            tooltip="Sync"
            tooltipOptions={{ position: "top" }}
            rounded
            outlined
            severity="secondary"
            onClick={() => SyncConfirm(rowData)}
            disabled={isLoading}
          />
          <OverlayPanel ref={op} onHide={() => setIsLoading(false)} showCloseIcon>
            {isLoading && (
              <div className="p-d-flex p-ai-center">
                <ProgressSpinner />
              </div>
            )}
          </OverlayPanel>


          {/* <Button
            className="p-2 border-0 rounded-circle"
            tooltip="Insights"
            tooltipOptions={{ position: "top" }}
            rounded
            outlined
            severity="secondary"
            onClick={() => navigate("/insights")}
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 28 28"
              fill="none"
              className="p-1"
            >
              <path
                d="M5.75 24.25V15.75H9.25V24.25H5.75ZM13.25 5.75H16.75V24.25H13.25V5.75ZM20.75 24.25V12H24.25V24.25H20.75Z"
                stroke="#7E6CF4"
                stroke-width="1.5"
              />
            </svg>
          </Button> */}
          <Button
            className="rounded-circle"
            icon="pi pi-cog fs-6"
            tooltip="Settings"
            tooltipOptions={{ position: "top" }}
            rounded
            outlined
            severity="secondary"
            onClick={() => handleButtonClick(rowData)}
          />

          <Button
            className="rounded-circle"
            icon="pi pi-undo"
            tooltip="Reset"
            tooltipOptions={{ position: "top" }}
            rounded
            outlined
            severity="secondary"
            onClick={() => RestoreConfirm(rowData)}
            disabled={isResetLoading}
          />

        </div>
        <SettingDialog
          visible={visible}
          setVisible={setVisible}
          rowData={rowData}
          propertyDetail={
            propertyDetail?.data?.recordId === rowData?.recordId
              ? propertyDetail?.data
              : {}
          }
        />
      </React.Fragment>
    );
  };

  const automateBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <InputSwitch
          onIcon="pi pi-check"
          offIcon="pi pi-times"
          checked={rowData?.autoUpdateStatus ? true : false}
          onChange={(e) => {
            setDisable(true);
            automateProperty(e.value, rowData).finally(() => setDisable(false));
          }}
          disabled={disable}
          className="w-8rem"
        />
      </React.Fragment>
    );
  };

  const propertyBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className="d-flex align-items-center">
          <img
            onClick={() => navigate("/propertydetails", { state: rowData })}
            className="propertyImg rounded-2 me-2"
            src={
              rowData?.thumbnailUrl ||
              process.env.PUBLIC_URL + "/Assets/images/house_placeholder.png"
            }
            onError={(e) => {
              e.target.src =
                process.env.PUBLIC_URL + "/Assets/images/house_placeholder.png"; // replace with a default image
            }}
            alt="Not available"
          />
          <p className="mb-0 ">{rowData?.name}</p>
        </div>
      </React.Fragment>
    );
  };

  const PropertyNickNameTemplate = (rowData) => {
    const [editNickName, setEditNickName] = useState(
      rowData?.nickName || rowData?.name
    );

    const [editVisible, setEditVisible] = useState(false);
    const headerElement = (
      <div className="inline-flex align-items-center justify-content-center gap-2 mb-3">
        <span className="font-bold white-space-nowrap ">Nick Name</span>
      </div>
    );

    const footerContent = (
      <div className="mt-4">
        <Button
          className="btn popup-cancel-btn rounded-pill float-start"
          label="Cancel"
          onClick={() => setEditVisible(false)}
        />
        <Button
          className="btn popup-save-btn rounded-pill"
          label="Save"
          onClick={() => saveNickName(rowData, editNickName)}
          autoFocus
        />
      </div>
    );

    const onNameFieldEdit = (e) => {
      setEditNickName(e.target.value);
    };

    const saveNickName = async (rowData, editNickName) => {
      await dispatch(
        includeExcludeUpdate({
          recordId: rowData.recordId,
          nickName: editNickName,
        })
      ).unwrap();
      dispatch(HostawayLIstings());
      setEditVisible(false);

      return;
    };
    return (
      <React.Fragment>
        <div className="d-flex align-items-center">
          <p className="mb-0 text-justify">
            {rowData.nickName || rowData.name}
          </p>

          <div className="tagCol">
            <Button
              className="btn border-0"
              onClick={() => setEditVisible(true)}
            >
              <img
                className="ms-2"
                src={
                  process.env.PUBLIC_URL + "/Assets/images/icons/edit_text.svg"
                }
                alt=""
              />
            </Button>
            <Dialog
              className="nick_dialog p-3 background-white rounded-17"
              visible={editVisible}
              modal
              header={headerElement}
              footer={footerContent}
              style={{ maxWidth: "500px", minWidth: "400px" }}
              onHide={() => {
                if (!editVisible) return;
                setEditVisible(false);
              }}
            >
              <textarea
                class="form-control"
                id="nickName"
                name="nickName"
                value={editNickName}
                onChange={(e) => onNameFieldEdit(e)}
              ></textarea>
            </Dialog>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex align-items-center">
          <p
            className={rowData?.dataSource
              ? "lime-30 color-dark fw-light rounded-pill fs-14 px-3 py-2 exMono tags mb-0"
              : rowData?.dataSource == "hostfully"
                ? "red-10 color-dark fw-light rounded-pill fs-14 px-3 py-2 exMono tags mb-0"
                : "purole-15 color-dark fw-light rounded-pill fs-14 px-3 py-2 exMono tags mb-0"
            }
          >
            {rowData?.dataSource}
          </p>
        </div>
      </>
    );
  };

  const header = renderHeader();
  return (
    <>
      <div id="listing_page">
        <Header />
        <div id="wrapper">
          <SideBar />
          <SubscriptionModal />

          {!isSpinner ? (
            <section id="content-wrapper">
              <div className="welcome-user  ">
                <div className="d-flex justify-content-between align-items-center" >
                  <h2 className=" content-title mb-0 exDemiBold mb-3">
                    Property Listing
                  </h2>
                  {
                    user !== null && (
                      <h5>Impersonated User: {user}</h5>
                    )}
                 
                  {
                    isProgressBar &&
                    (
                      <div className="cardProgressBar">
                        <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="transparent" animationDuration=".5s" />
                      </div>
                    )
                  }
                </div>
                <div className="headerContent shadow-sm rounded-17">
                  <DataTable
                    className="editNick"
                    value={listData}
                    selectionMode={"checkbox"}
                    selection={selectedProperties}
                    onSelectionChange={(e) => onSelectedProperties(e)}
                    dataKey="id"
                    tableStyle={{ minWidth: "50rem" }}
                    responsiveLayout="scroll"
                    size="small"
                    showGridlines
                    removableSort
                    paginator
                    rows={6}
                    rowsPerPageOptions={[6, 20, 30, 50]}
                    paginatorTemplate="RowsPerPageDropdown  PrevPageLink CurrentPageReport NextPageLink "
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    filters={filters}
                    header={header}
                  >
                    <Column
                      selectionMode="multiple"
                      headerStyle={{ width: "3rem" }}
                      field="id"
                    ></Column>
                    <Column
                      field="automated"
                      header="Automated"
                      body={automateBodyTemplate}
                    ></Column>
                    <Column
                      field="airbnbSummary"
                      header="Property"
                      body={propertyBodyTemplate}
                      sortable
                    ></Column>
                    <Column field="address" header="Address" sortable></Column>
                    <Column
                      field="updatedAt"
                      header="Last Updated"
                      sortable
                      body={(row) => <span>{DateFormat(row.updatedAt)}</span>}
                    ></Column>
                    <Column
                      field="name"
                      header="Nick Name"
                      body={PropertyNickNameTemplate}
                      sortable
                    ></Column>
                    <Column
                      field="tagName"
                      header="Tags"
                      body={statusBodyTemplate}
                    ></Column>
                    <Column
                      field="recordId"
                      header="ListID"
                      className="me-2"
                      sortable
                    ></Column>
                    <Column
                      field="id"
                      header="Actions"
                      body={ActionBodyTemplate}
                    ></Column>
                  </DataTable>
                </div>

                {/* Confirmation dialog to add bullet  */}
                <ConfirmDialog className="toggleOffAlert" />
              </div>
            </section>
          ) : (
            <div className="lising-loader">
              <div className="">
                <ProgressSpinner
                  style={{ height: "50px" }}
                  strokeWidth="3"
                  animationDuration="0.8s"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default PropertyListing;
