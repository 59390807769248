import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Tag } from "primereact/tag";
import { getUserAuditLogDataOfPropertyListingId } from "../../Redux/Features/listReducer/listSlice";
import { DateFormat } from "../../Utils/constant/commonFunction";

function AllUpdatedHistory() {
  const params = useParams();
  const dispatch = useDispatch();
  const [historyDetails, setHistoryDetails] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const { getUserAuditLogDataOfPropertyListingIdData } = useSelector(
    (state) => state.List
  );
  
  useEffect(() => {
    dispatch(
      getUserAuditLogDataOfPropertyListingId({
        recordId: params?.recordId,
      })
    );
  }, [params?.recordId]);

  useEffect(() => {
    if (getUserAuditLogDataOfPropertyListingIdData != "") {
      setHistoryDetails(getUserAuditLogDataOfPropertyListingIdData?.data);
    }
  }, [getUserAuditLogDataOfPropertyListingIdData]);

  const onRowExpand = (event) => {
    // toast.current.show({
    //   severity: "info",
    //   summary: "Product Expanded",
    //   detail: event.data.name,
    //   life: 3000,
    // });
  };

  const onRowCollapse = (event) => {
    // toast.current.show({
    //   severity: "success",
    //   summary: "Product Collapsed",
    //   detail: event.data.name,
    //   life: 3000,
    // });
  };

  const allowExpansion = (rowData) => {
    return rowData;
  };

  const onRowClick = (e) => {
    const rowId = e.data.id;
    const currentExpandedRows = { ...expandedRows };

    if (currentExpandedRows[rowId] === true) {
      delete currentExpandedRows[rowId];
    } else {
      currentExpandedRows[rowId] = true;
    }
    setExpandedRows(Object.keys(currentExpandedRows).length ? currentExpandedRows : null);
  }

  const rowExpansionTemplate = (rowData) => {
    return (
      <div className="p-3">
        <h6 className="exMedium fs-18 color-black">Summary</h6>
        <p>{rowData.airbnbSummary}</p>
        <h6 className="exMedium fs-18 color-black">Space</h6>
        <p>{rowData.airbnbSpace}</p>
        <div class="updated_analytics mt-4">
          <div class="row gy-6">
            <div className="col-4">
              <div className="card border-0 shadow-sm d-flex align-items-center justify-content-evenly flex-row rounded-17 p-xl-3 p-2 purple-15">
                <h3 className="exBold fs-2 mb-0">${historyDetails?.totalRevenue || 0}K</h3>
                <div>
                  <p className="mb-1 exMedium fs-6">Total Revenue</p>
                  {/* <button className="btn btn-sm rounded-pill me-2 btn-secondary purple-25 text-navy border-0">
                    <img
                      className="me-1"
                      src={
                        process.env.PUBLIC_URL +
                        "/Assets/images/icons/growth.svg"
                      }
                      alt=""
                    />
                    20%
                  </button> */}
                </div>
              </div>
            </div>
             
            <div className="col-4">
              <div className="card border-0 shadow-sm d-flex align-items-center justify-content-evenly flex-row rounded-17 p-xl-3 p-2 mint-30">
                <h3 className="exBold fs-2 mb-0 me-2">{historyDetails?.totalNumberOfBooking || 0}</h3>
                <div>
                  <p className="mb-1 exMedium fs-6">Number of Bookings</p>
                  {/* <button className="btn btn-sm rounded-pill btn-secondary mint-40 text-navy border-0">
                    <img
                      className="me-1"
                      src={
                        process.env.PUBLIC_URL +
                        "/Assets/images/icons/growth.svg"
                      }
                      alt=""
                    />
                    18%
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const titleBodyTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex align-items-center">
          {/* <img className="propertyImg rounded-2 me-3 " src={process.env.PUBLIC_URL + "/Assets/images/property_img01.jpg"}></img> */}
          <p className="fs-14 m-0 lh-lg">{rowData.title}</p>
        </div>
      </>
    );
  };
  const includedData = (rowData) => {
    return (
      <>
        <Tag
          className={
            rowData.included == "Event"
              ? "px-3 py-2 mint-30 text-navy"
              : rowData.included == "Attraction"
              ? "px-3 py-2 lime-30 text-navy"
              : "px-3 py-2 purple-15 text-navy"
          }
          value={rowData.logType}
          rounded
        ></Tag>
      </>
    );
  };

  const dateBodyTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex align-items-center">
          <p className="fs-14 m-0 lh-lg">{DateFormat(rowData?.createdAt)}</p>
        </div>
      </>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </IconField>
      </div>
    );
  };

  const header = renderHeader();
  return (
    <>
      <DataTable
        className="update_data rounded-17"
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        onRowExpand={onRowExpand}
        onRowCollapse={onRowCollapse}
        rowExpansionTemplate={rowExpansionTemplate}
        header={header}
        value={historyDetails}
        dataKey="id"
        tableStyle={{ minWidth: "50rem" }}
        responsiveLayout="scroll"
        size="small"
        showGridlines
        stripedRows
        removableSort
        paginator
        rows={5}
        rowsPerPageOptions={[5, 10, 25, 50]}
        paginatorTemplate="RowsPerPageDropdown  PrevPageLink CurrentPageReport NextPageLink "
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        filters={filters}
        onRowClick={onRowClick}
        
      >
        <Column
          field="title"
          header="Title"
          sortable
          body={titleBodyTemplate}
        ></Column>
        <Column
          field="date"
          header="Date"
          body={dateBodyTemplate}
          sortable
        ></Column>
        <Column
          field="included"
          header="Included Aspects"
          sortable
          body={includedData}
        ></Column>
        <Column
          field="upmode"
          header="Update Mode"
          sortable
          body="Automated"
        ></Column>
        <Column 
        className="" 
        header="-" 
        expander={allowExpansion} 
        />
      </DataTable>
    </>
  );
}

export default AllUpdatedHistory;
