import React, { useState } from "react";
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { FilterMatchMode } from "primereact/api";
import { Tag } from "primereact/tag";
import { DateFormat } from "../../../Utils/constant/commonFunction";

function PastInvoices(props) {
  const { purchasedSubscriptions} = props;
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

    const generatePDF = (data) => {
      const doc = new jsPDF();
     doc.setFontSize(16);
     doc.text("User Invoice Data", 105, 20, { align: "center" });
     const tableColumn = ["Email", "Customer Id", "Price", "Date"];
     const tableRows = [
       [
         data?.userEmail,
         data?.mollieCustomerId,
         `$${Number(data?.SubscriptionPrize).toFixed(2)}`,
         DateFormat(data?.createdAt)
       ]
     ];
     // Create a table using autotable
     doc.autoTable({
       head: [tableColumn],
       body: tableRows,
       startY: 30, // Start the table below the title
       theme: 'grid', // Table theme
       styles: { cellPadding: 5, fontSize: 10 },
       headStyles: { fillColor: "#7c6cf4" },
       didDrawCell: (data) => {
         doc.setTextColor(255, 255, 255);
       }
     });
     doc.save("Invoice.pdf");
};
  
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </IconField>
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          type="button"
          icon="pi pi-download"
          onClick={(e) =>generatePDF(rowData)}
          className="fw-bolder"
          rounded
          outlined
          severity="secondary"
        />
      </React.Fragment>
    );
  };

  const statusBodyTemplate = (varient) => {
    return (
      <Tag
        value={varient.susbcriptionsStatus}
        className={
          varient.susbcriptionsStatus === "active"
            ? "mint-30 color-dark fw-light rounded-pill fs-14 px-3 py-2 exMono"
            : varient.susbcriptionsStatus === "inactive"
            ? "red-10 color-dark fw-light rounded-pill fs-14 px-3 py-2 exMono"
            : "lime-30 color-dark fw-light rounded-pill fs-14 px-3 py-2 exMono"
        }
      ></Tag>
    );
  };

  const header = renderHeader();

  return (
    <div
      className="tab-pane fade shadow-sm mt-4 rounded-17"
      id="pastInvoices"
      role="tabpanel"
      aria-labelledby="pastInvoices-tab"
    >
      <DataTable
        value={purchasedSubscriptions?.purchasedSubscriptionDataByUser || []}
        dataKey="id"
        tableStyle={{ minWidth: "50rem" }}
        responsiveLayout="scroll"
        size="small"
        showGridlines
        removableSort
        paginator
        rows={5}
        rowsPerPageOptions={[5, 10, 25, 50]}
        paginatorTemplate="RowsPerPageDropdown  PrevPageLink CurrentPageReport NextPageLink "
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        filters={filters}
        filterDisplay="row"
        globalFilterFields={["invoiveNumber", "status", "date", "amount"]}
        header={header}
        emptyMessage="No Invoice found."
      >
        <Column
          field="id"
          header="Invoice Number"
          className="exMono"
          sortable
        ></Column>
        <Column
          field="susbcriptionsStatus"
          header="Status"
          sortable
          body={statusBodyTemplate}
        ></Column>
        <Column
          field="createdAt"
          header="Date"
          className="exMono"
          sortable
          body={(row) => <span>{DateFormat(row.createdAt)}</span>}
        ></Column>
        <Column
          field="SubscriptionPrize"
          header="Amount"
          className="exMono"
          sortable
        ></Column>
        <Column
         header="Action" 
         body={actionBodyTemplate}
         ></Column>
      </DataTable>
    </div>
  );
}
export default PastInvoices;
