// REGEX
const UPPER_REGEX = /^(?=.*[A-Z])/;
const LOWER_REGEX = /^(?=.*[a-z])/;
const NUMBER_REGEX = /^(?=.*[0-9])/;
const SPCL_CHRCT_REGEX = /^(?=.*[!@#\$%\^&\*])/
const MAX_TEN_DIGIT_REGEX = /(?=.{10,})/;
const FIRST_NAME_REGEX = /\W*(\w)\w*/g;
const PHONE_NMBR_REGEX =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const US_PHONE_REGEX = /(\d{3})(\d{3})(\d{4})/;
const CARD_EXPIRY_REGEX = /\b(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})\b/;

const phoneNumberMask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const creditCardMask = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const Regex = {
  LOWER_REGEX,
  UPPER_REGEX,
  NUMBER_REGEX,
  SPCL_CHRCT_REGEX,
  MAX_TEN_DIGIT_REGEX,
  PHONE_NMBR_REGEX,
  US_PHONE_REGEX,
  FIRST_NAME_REGEX,
  CARD_EXPIRY_REGEX,
  phoneNumberMask
};
export default Regex;
