import { toast } from "react-toastify";
import moment from "moment"
import { getUser } from "../../Redux/Features/LocalStorage";

/* Date format convert m-d-y */
export const DateFormat = (currentDAte) => {
  const date = moment(currentDAte);
const type= getUser().dateFormat
  return date.format(type?type:"MM-DD-YYYY");
};

/** Date and time format*/
export const formatedDateTime = (dateTime)=>{
  let temp = new Date(dateTime)
  return `${temp.toDateString()} at ${convertTo12HourFormat(`${temp.getHours()}:${temp.getMinutes()}`)}`
}

export const convertTo12HourFormat = (time) =>{
  // Split the time string into hours and minutes
  let [hour, minute] = time.split(':').map(Number);

  // Determine the period (AM or PM)
  const period = hour >= 12 ? 'PM' : 'AM';

  // Convert hour from 24-hour format to 12-hour format
  hour = hour % 12 || 12; // Adjust hour to 12-hour format (0 becomes 12)

  // Pad minutes with leading zero if necessary
  minute = minute.toString().padStart(2, '0');

  // Return the formatted time
  return `${hour}:${minute} ${period}`;
}

/* Number and price with only 2 decimal */
export const PriceOnely2decimals = (num) => {
  return (Math.round(num * 100) / 100).toFixed(2);
};

/* Set token in the header */
export const HeaderToken = (token) => {
  return  {headers: { "authorization": `bearer ${token}` }}
};

/* First charatef cammel case */
export const FirstCamellCase = (name) =>{
  return name[0].toUpperCase() + name.slice(1)
}

 // Convert the binary data to a Base64 string
 export const binaryToBase64String = (data)=>{
  const base64Image = Buffer.from(data, 'binary').toString('base64');
  return `data:image/jpeg;base64,${base64Image}`;
 }

export const arrayBufferToBase64 = (buffer) => {
  const bytes = new Uint8Array(buffer);
  const binary = bytes.reduce((acc, byte) => acc + String.fromCharCode(byte), '');
  return `data:image/jpeg;base64,${window.btoa(binary)}`;
};

/* Succcess notification */
export const successToast = (msg)=>{toast.success(msg);
}
/* Error notification */
export const errorToast = (msg)=>{toast.error(msg);}
