import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getUser } from "../../Redux/Features/LocalStorage";
import Header from "../../Layouts/HeaderPage";
import SideBar from "../../Layouts/SideBar";
import AccountSetting from "../../Components/Settings/AccountSetting";
import BillingSetting from "../../Components/Settings/BillingSetting";
import NotificationSetting from "../../Components/Settings/NotificationSetting";
import PastInvoices from "../../Components/Settings/PastInvoices";
import PrivacySetting from "../../Components/Settings/PrivacySetting";
import SubscriptionPlan from "../../Components/Settings/SubscriptionPlan";
import { downloadPdf } from "../../Redux/Features/settingReducer/settingSlice";
import { getPurchasedSubscriptions } from "../../Redux/Features/settingReducer/settingSlice";


const Settings = () => {
  const User = getUser();
  const dispatch = useDispatch();
  const { purchasedSubscriptions } = useSelector((state) => state.Setting);

  useEffect(() => {
    dispatch(getPurchasedSubscriptions());
  }, []);

  return (
    <>
      <div id="setting_page">
        <Header />
        <div id="wrapper">
          <SideBar />
          <section id="content-wrapper">
            <div class="welcome-user">
              <h2 className=" content-title mb-0 exDemiBold mb-2">Settings</h2>
              {/* <p className="color-muted mb-3">
                Lorem ipsum dolor sit amet, consectetur.
              </p> */}
            </div>
            <ul className="nav nav-tabs mb-3" id="settingTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link color-muted px-3 pb-2 border-0 exMedium fs-18 active"
                  id="account-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#account"
                  type="button"
                  role="tab"
                  aria-controls="account"
                  aria-selected="true"
                >
                  Account Settings
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link color-muted px-3 pb-2 border-0 exMedium fs-18"
                  id="billing-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#billing"
                  type="button"
                  role="tab"
                  aria-controls="billing"
                  aria-selected="false"
                >
                  Billing Settings
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link color-muted px-3 pb-2 border-0 exMedium fs-18"
                  id="pastInvoices-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#pastInvoices"
                  type="button"
                  role="tab"
                  aria-controls="pastInvoices"
                  aria-selected="false"
                >
                  Past Invoices
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link color-muted px-3 pb-2 border-0 exMedium fs-18"
                  id="notification-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#notification"
                  type="button"
                  role="tab"
                  aria-controls="notification"
                  aria-selected="false"
                >
                  Notification Settings
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link color-muted px-3 pb-2 border-0 exMedium fs-18"
                  id="privacy-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#privacy"
                  type="button"
                  role="tab"
                  aria-controls="privacy"
                  aria-selected="false"
                >
                  Privacy Settings
                </button>
              </li>
              {User?.roleId === 2 && (
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link color-muted px-3 pb-2 border-0 exMedium fs-18"
                    id="subscription-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#subscription"
                    type="button"
                    role="tab"
                    aria-controls="subscription"
                    aria-selected="false"
                  >
                    Subscription Plan
                  </button>
                </li>
              )}
            </ul>
            <div className="tab-content" id="settingTabContent">
              {/* <!-- ----------------------Account setting tab content----------------------------- --> */}
              <AccountSetting />

              {/* <!-- ----------------------Billing setting tab content----------------------------- --> */}
              <BillingSetting />

              {/* <!-- ----------------------Past Invoices tab content----------------------------- --> */}
              <PastInvoices
                purchasedSubscriptions={purchasedSubscriptions}
                downloadPdf={downloadPdf}
              />

              {/* <!-- ----------------------Notification setting tab content----------------------------- --> */}
              <NotificationSetting />

              {/* <!-- ----------------------Pirivacy setting tab content----------------------------- --> */}
              <PrivacySetting />

              {/* <!-- ----------------------Subscription Plan tab content----------------------------- --> */}
              <SubscriptionPlan />
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Settings;
