import axios from "axios";
import { getToken } from "../LocalStorage";
import { HeaderToken } from "../../../Utils/constant/commonFunction";
import {
  BASE_URL,
  PURCHASE_SUBSCRIPTIONS,
  DOWNLOAD_PDF
} from "../../../Config/Config"

//Download pdf file
const downloadPdf = async (data) => {
  const response = await axios.post(
    BASE_URL + DOWNLOAD_PDF,
    data,
    {
      headers:{
        ...( HeaderToken(getToken()).headers),
        
      },
      responseType: 'blob',
    }
  );
  return response?.data;
};

// Fetch purchased subscriptions
const getPurchasedSubscriptions = async () => {
  const response = await axios.get(
    BASE_URL + PURCHASE_SUBSCRIPTIONS,
    HeaderToken(getToken())
  );
  return response?.data;
};

const settingService = {
  getPurchasedSubscriptions,
  downloadPdf
}
export default settingService;