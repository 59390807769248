import React from "react";
import Header from "../../Layouts/HeaderPage";
import SideBar from "../../Layouts/SideBar";
import { NavLink } from "react-router-dom";
import { HOSTAWAYCONNECTION, CONNECTEDHOSTAWAYUSERLIST, GUESTYCONNECTION } from "../../Routes/Constant";
import { useSelector } from "react-redux";
import { getUser } from "../../Redux/Features/LocalStorage";
import SubscriptionModal from "../SubscriptionModal"

function Connection() {
  const userData = getUser();
  const { CheckHoatawayConnectionData } = useSelector((state) => state.Auth);

  return (
    <>
      <div id="connection_page">
        <Header />
        <div id="wrapper">
          <SideBar />
          <SubscriptionModal/>
          <section id="content-wrapper">
            <div className="welcome-user">
              <h2 class=" content-title mb-0 exDemiBold mb-3">
                Welcome to AirBnb list
              </h2>
              {/* <p class="text-muted mb-4">
                Lorem ipsum dolor sit amet, consectetur.
              </p> */}
            </div>
            <div class="row gy-4">
              <div class="col-xl-3 col-lg-6 col-md-6">
                <div class="card p-2 ">
                  <img
                    src={process.env.PUBLIC_URL + "/Assets/images/hostaway.png"}
                    class="card-img-top img-fluid m-auto"
                    alt="..."
                  />
                  <div class="card-body">
                    <h4 class="text-subtitle1 card-title exMedium">Hostaway</h4>
                    <p class="text-caption card-text text-navy-light">
                      Automate conversations directly in Hostaway, turn SMS,
                      Chat, WhatsApp, etc. conversations into bookings with one
                      click.
                    </p>
                    {userData?.roleId === 1 ? (
                    <NavLink to={CONNECTEDHOSTAWAYUSERLIST}>
                      <div className="mt-3">
                        <button className="Powerup-btn btn rounded-pill mb-2 px-3">
                          Connected Users List
                        </button>
                      </div>
                    </NavLink>
                        ) : (
                    <NavLink to={HOSTAWAYCONNECTION}>
                      <div className="mt-3">
                        <button className="Powerup-btn btn rounded-pill mb-2 px-3">
                          {CheckHoatawayConnectionData &&
                          CheckHoatawayConnectionData?.status &&
                          CheckHoatawayConnectionData?.validConnection?.find(item => item?.dataSource === 'hostaway')
                            ? `Connected`
                            : `Connection`}
                        </button>
                      </div>
                    </NavLink>
                   )}
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6 col-md-6">
                <div class="card p-2 ">
                  <img
                    src={process.env.PUBLIC_URL + "/Assets/images/guesty.png"}
                    class="card-img-top img-fluid m-auto"
                    alt="..."
                  />
                  <div class="card-body">
                    <h4 class="text-subtitle1 card-title exMedium">Guesty</h4>
                    <p class="text-caption card-text text-navy-light">
                      Automate conversations directly in Guesty, turn SMS,
                      Chat, WhatsApp, etc. conversations into bookings with one
                      click.
                    </p>

                    <NavLink to={GUESTYCONNECTION}>
                      <div className="mt-3">
                        <button className="Powerup-btn btn rounded-pill mb-2 px-3"> 
                        {CheckHoatawayConnectionData &&
                          CheckHoatawayConnectionData?.status &&
                          CheckHoatawayConnectionData?.validConnection?.find(item => item?.dataSource === 'guesty')
                            ? `Connected`
                            : `Connection`}
                        </button>
                      </div>
                    </NavLink>
                  
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6 col-md-6">
                <div class="card p-2 ">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/Assets/images/hostfully.png"
                    }
                    class="card-img-top img-fluid m-auto"
                    alt="..."
                  />
                  <div class="card-body">
                    <h4 class="text-subtitle1 card-title exMedium">
                      Hostfully
                    </h4>
                    <p class="text-caption card-text text-navy-light">
                      Automate conversations directly in Hostfully, turn SMS,
                      Chat, WhatsApp, etc. conversations into bookings with one
                      click.
                    </p>
                    <div className="mt-3">
                      <button className="request-btn btn   rounded-pill mb-2 px-3">
                        Request Early Access
                      </button>
                    </div>
                  </div>
                  <div class="card-banner position-absolute start-0">
                    <p class=" px-2 py-1 fs-12 exMedium text-purple purple-15">
                      Comming Soon
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6 col-md-6">
                <div class="card p-2 ">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/Assets/images/pricelabs.png"
                    }
                    class="card-img-top img-fluid m-auto"
                    alt="..."
                  />
                  <div class="card-body">
                    <h4 class="text-subtitle1 card-title exMedium">
                      Pricelabs
                    </h4>
                    <p class="text-caption card-text text-navy-light">
                      Automate conversations directly in Pricelabs, turn SMS,
                      Chat, WhatsApp, etc. conversations into bookings with one
                      click.
                    </p>
                    <div className="mt-3">
                      <button className="request-btn btn rounded-pill mb-2 px-3">
                        Request Early Access
                      </button>
                    </div>
                  </div>
                  <div class="card-banner position-absolute start-0">
                    <p class=" px-2 py-1 fs-12 exMedium text-purple purple-15">
                      Comming Soon
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Connection;
