import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";

function DoughnutChart(props) {
  const { automatedAndUnautomatedListing } = props;
  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (automatedAndUnautomatedListing) {
      const documentStyle = getComputedStyle(document.documentElement);
      const data = {
        datasets: [
          {
            data: [
              automatedAndUnautomatedListing.totalUnautomatedData,
              automatedAndUnautomatedListing.totalAutomatedData,
              automatedAndUnautomatedListing.inactiveData,
            ],
            backgroundColor: [
              documentStyle.getPropertyValue("--grey"),
              documentStyle.getPropertyValue("--purple"),
              documentStyle.getPropertyValue("--lime"),
            ],
            hoverBackgroundColor: [
              documentStyle.getPropertyValue("--grey"),
              documentStyle.getPropertyValue("--purple"),
              documentStyle.getPropertyValue("--lime"),
            ],
          },
        ],
      };
      const options = {
        maintainAspectRatio: false,
        aspectRatio: 1,
        cutout: "65%",
      };

      setChartData(data);
      setChartOptions(options);
    }
  }, [automatedAndUnautomatedListing]);

  return (
    <div className="booking_sites card p-3 border-0 shadow-sm rounded-17 h-100">
      <h5 className="mb-3 exDemiBold fs-22 ">Listing Analysis</h5>
      <div className="row gy-4 align-items-center">
        <div className="col-sm-4 position-relative my-auto">
          <Chart
            type="doughnut"
            data={chartData}
            options={chartOptions}
            className=""
          />
          <div className="position-absolute top-50 start-50 translate-middle mt-1">
            {/* <small className="mb-0 text-purple">Automated</small> */}

            <h5 className="fs-2 exMedium text-center mb-0 text-purple">
              {automatedAndUnautomatedListing.percentage || 0}%
            </h5>
          </div>
        </div>
        <div className="col-sm-8 ">
          <ul className="mb-0">
            <li className="purple-bullet pb-0 mb-2 d-flex align-items-center ">
              Automated listing{" "}
              <p className="text-navy exMedium fs-18 mb-0 ms-auto">
                {automatedAndUnautomatedListing.totalAutomatedData || 0}
              </p>
            </li>
            <li className="grey-bullet pb-0 mb-2  d-flex align-items-center ">
              Unautomated listing{" "}
              <p className="text-navy exMedium fs-18 mb-0 ms-auto">
                {automatedAndUnautomatedListing.totalUnautomatedData || 0}
              </p>
            </li>
            <li className="lime-bullet pb-2 mb-3 border-bottom d-flex align-items-center color-dark">
             
                Inactive on Autorank{" "}
            
              <p className="text-navy exMedium fs-18 mb-0 ms-auto">
                {automatedAndUnautomatedListing.inactiveData || 0}
              </p>
            </li>

            <li className="pb-0 d-flex justify-content-between align-items-center">
              <div className="w-100">
                <p className="mb-2">Untapped Revenue Potential</p>{" "}
                <button className="btn btn-sm rounded-pill text-red red-10 " >
                  <svg
                    className="me-1 rotate-180"
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_699_2125)">
                      <path
                        d="M2.70763 7.2866C2.89516 7.4756 3.14947 7.58178 3.41463 7.58178C3.6798 7.58178 3.9341 7.4756 4.12163 7.2866L5.91463 5.47894L5.91463 12.1188C5.91463 12.3862 6.01999 12.6426 6.20753 12.8317C6.39506 13.0207 6.64942 13.127 6.91463 13.127C7.17985 13.127 7.4342 13.0207 7.62174 12.8317C7.80927 12.6426 7.91463 12.3862 7.91463 12.1188L7.91463 5.47894L9.70763 7.2866C9.89623 7.47025 10.1488 7.57186 10.411 7.56957C10.6732 7.56727 10.924 7.46124 11.1095 7.27432C11.2949 7.08739 11.4 6.83453 11.4023 6.57019C11.4046 6.30585 11.3038 6.05119 11.1216 5.86104L7.62163 2.33243C7.4341 2.14343 7.1798 2.03725 6.91463 2.03725C6.64947 2.03725 6.39516 2.14343 6.20763 2.33243L2.70763 5.86104C2.52016 6.0501 2.41485 6.30649 2.41485 6.57382C2.41485 6.84115 2.52016 7.09754 2.70763 7.2866Z"
                        fill="#de3232"
                      />
                    </g>
                  </svg>
                  <span className="exMedium me-1" style={{ filter: 'blur(2px)' }}>54%</span>
                </button>
              </div>
              <p className="text-navy fs-18 exMedium mb-0" style={{ filter: 'blur(2px)' }}>$245K</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default DoughnutChart;
