export const FIRST_NAME = "First name must be at least 3 characters";
export const CSV_CODE = "CVV must be 3 characters";
export const LAST_NAME = "Last name must be at least 3 characters";
export const REQUIRED_FIELD = "This field is required";
export const ACCOUNT_ID_REQUIRED_FIELD= "Account Id is required";
export const ACCOUNT_TOKEN_REQUIRED_FIELD= "Account Token is required";
export const CONFIRM_PASSWORD_REQUIRED_FIELD= "Confirm Password is required";
export const OTP_REQUIRED_FIELD = "OTP is required";
export const NEW_PASSWORD_REQUIRED_FIELD= "New Password is required";
export const PHONE_NUMBER_REQUIRED_FIELD = "Phone Number is required";
export const FIRST_NAME_REQUIRED_FIELD = "First Name is required";
export const LAST_NAME_REQUIRED_FIELD= "Last Name is required";
export const EMAIL_REQUIRED_FIELD = "Email is required";
export const PASSWORD_REQUIRED_FIELD = "Password is required";
export const OLD_PASSWORD_REQUIRED_FIELD = " Old Password is required";
export const MIN_BILLING_ADDRESS = "Address must be at least 3 characters";
export const MAX_BILLING_ADDRESS = "Address must be less than or equal to 50 characters";
export const ADDRESS_REQUIRED_FIELD = "Address is required";
export const CITY_REQUIRED_FIELD = "City is required";
export const STATE_REQUIRED_FIELD = "State is required";
export const COUNTRY_REQUIRED_FIELD = "Country is required";
export const PINCODE_REQUIRED_FIELD = "Zip Code is required";
export const PROPERTY_LIMIT_REQUIRED_FIELD = "Property Limit is required";
export const VAT_NUMBER_REQUIRED_FIELD = "Company Vat Number is required";
export const CONSUMER_REQUIRED_FIELD= "Customer Name is required";
export const CONSUMER_ACCOUNT_REQUIRED_FIELD= "Account Number is required";
export const PAYMENT_METHOD_REQUIRED_FIELD= "Payment method is required";
export const SUBSCRIPTION_TYPE_REQUIRED_FIELD= "Please select subscription plan";
 
export const INVALID_EMAIL = "Please enter a valid email address";
export const INVALID_RE_EMAIL = "Re-email does not match with email";
export const MIN_PASSWORD = "Password must be a minimum of 8 characters";
export const MAX_PASSWORD= "Password must be less than or equal to 16 characters";
export const PASSWORD_lOWWER = "Must Contain One Lowercase Character";
export const PASSWORD_UPPER = "Must Contain One Uppercase Character";
export const PASSWORD_NUMBER = "Must Contain One Number Character";
export const PASSWORD_SPECIAL_CHARACTER = "Must Contain One Special Case Character";

export const RE_PASSWORD = "Confirm Password does not match with New Password";
export const PHONE_NUMBER = "Phone Number must be 10 digits";
export const PHONE_NUMBER_MAX = "Phone number must not exceed 14 digits"
export const NUMBER_ONLY = "Must be Number only";
// signup step 2
export const MIN_ZIP_CODE = "Zip code at least 5 digits";
export const MAX_ZIP_CODE = "Zip code must be 5 digits";
export const MAX_PINCODE = "Zip code must be less than or equal to 10 characters";
export const MIN_OTP_LENGTH = "Activation Code At least 6 digits";
export const MAX_OTP_LENGTH = "Activation Code must be 6 digits";
export const CREDIT_NUMBER = "Credit number must be 16 digits";
export const EXPIRE_DATE = "Not a valid expiration date";
export const SECURITY_CODE = "CVV must be 3 digit";
export const CREDIT_LAST_FOUR_DIGIT = "Credit card number must be 4 digit";
export const STRENGTH_PASSWORD ="Must Contain 10 Characters, One Uppercase, One Lowercase, One Number and one special case Character";
export const MAX_LENGTH = "Max character 50 exceed";
export const EXTRA_TRIM = " Please Remove extra spaces";
export const TERMSANDCOND = "You must accept the terms and conditions";
