import React, { useEffect, useState } from "react";
import Header from "../../Layouts/HeaderPage";
import SideBar from "../../Layouts/SideBar";
import { useDispatch, useSelector } from "react-redux";
import {
  GetUserListApi,
  inActivateUser,
  makeAdminImpersonateUser,
  adminIncressesLimitOfListing,
} from "../../Redux/Features/authReducer/authSlice";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { DateFormat } from "../../Utils/constant/commonFunction";
import Swal from "sweetalert2";
import { Dialog } from "primereact/dialog";
import { ErrorMessage, Field, Form, Formik } from "formik";
import TextError from "../../Components/TextError/index";
import { PropertyLimitSchema } from "../../Utils/Validation/Validation";

function UserList() {
  const dispatch = useDispatch();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [showIncreaseModal, setShowIncreaseModal] = useState(false);
  const [handleIncrease, sethandleIncreaseLimit] = useState();
  const [propertyLimit, setPropertyLimit] = useState('');

  const ListingLimitInitialValues = {
    propertyLimit: handleIncrease?.propertyLimit || 0,
  };

  const user = localStorage.getItem("ImpersonateUser");
  const { GetUserListData, inActiveUserData, makeAdminImpersonateUserData } =
    useSelector((state) => state.Auth);

  // Effect to set the initial value from propertyDetails
  useEffect(() => {
    if (handleIncrease && handleIncrease?.propertyLimit) {
      setPropertyLimit(handleIncrease?.propertyLimit);
    }
  }, [handleIncrease]);

  useEffect(() => {
    dispatch(GetUserListApi());
  }, [inActiveUserData]);

  const inActivateUserByAdmin = (rowData) => {
    dispatch(
      inActivateUser({
        userId: rowData.id,
        userEmail: rowData.email,
        userStatus: rowData?.status === "active" ? "inactive" : "active",
      })
    );
  };

  const impersonateUserByAdmin = (rowData) => {
    localStorage.setItem("ImpersonateUser", rowData?.fname);
    dispatch(makeAdminImpersonateUser({ inpersonateUserId: rowData?.id }));
    Swal.fire({
      title: "Success",
      text: "Admin impersonated successfully",
      icon: "success",
      timer: 1300,
      showConfirmButton: false,
      width: "300px",
      customClass: { popup: "small-swal" },
    });
  };

  const handleIncreaseLimit = (rowData) => {
    setShowIncreaseModal(true);
    sethandleIncreaseLimit(rowData)
  };

  const handleChange = (e) => {
    const value = e.target.value;
    // Check if the value is a number and has a maximum length of 4
    if (/^\d{0,3}$/.test(value)) {
      setPropertyLimit(value);
    }
  };

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2 mb-3">
      <span className="font-bold white-space-nowrap ">Set Property Listing Limit</span>
    </div>
  );

  const handleSaveLimit = async (values) => {
    try {
      await dispatch(adminIncressesLimitOfListing({ userId: handleIncrease?.id, propertyLimit: propertyLimit }))
        .unwrap()
        .then((result) => {
          if (result?.success) {
            dispatch(GetUserListApi());
            setShowIncreaseModal(false);
          }
        })
    }
    catch (error) {
      console.error(error);
      setShowIncreaseModal(false);
    }
  }


  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          className="rounded-circle"
          icon="pi pi-user-edit fs-5"
          tooltip="Disable User"
          tooltipOptions={{ position: "top" }}
          rounded
          outlined
          severity="secondary"
          onClick={() => inActivateUserByAdmin(rowData)}
        />
        <Button
          className="rounded-circle"
          icon="pi pi-users fs-5"
          tooltip="Impersonate User"
          tooltipOptions={{ position: "top" }}
          rounded
          outlined
          severity="secondary"
          onClick={() => impersonateUserByAdmin(rowData)}
        />

        <Button
          className="rounded-circle"
          icon="pi pi-plus fs-5"
          tooltip="Increase Limit"
          tooltipOptions={{ position: "top" }}
          rounded
          outlined
          severity="secondary"
          onClick={() => handleIncreaseLimit(rowData)}
        />
      </React.Fragment>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </IconField>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <>
      <div id="connection_page">
        <Header />
        <div id="wrapper">
          <SideBar />
          <section id="content-wrapper">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className=" content-title mb-0 exDemiBold mb-2">User List</h2>
              {user !== null && (
                <h5>Impersonated User: {user}</h5>
              )}
            </div>

            <DataTable
              className="shadow-sm rounded-17"
              header={header}
              value={GetUserListData?.data || []}
              selection={selectedProducts}
              onSelectionChange={(e) => setSelectedProducts(e.value)}
              dataKey="id"
              tableStyle={{ minWidth: "50rem" }}
              responsiveLayout="scroll"
              size="small"
              showGridlines
              stripedRows
              removableSort
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 25, 50]}
              paginatorTemplate="RowsPerPageDropdown  PrevPageLink CurrentPageReport NextPageLink "
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
              filters={filters}
            >
              <Column field="fname" header="First Name" sortable></Column>
              <Column field="lname" header="Last Name" sortable></Column>
              <Column field="email" header="Email Address" sortable></Column>
              <Column field="phoneNumber" header="Phone Number" sortable></Column>
              <Column field="status" header="Status" sortable></Column>
              <Column
                field="createdAt"
                header="Create Date"
                sortable
                body={(row) => <span>{DateFormat(row.createdAt)}</span>}
              ></Column>
              <Column className="d-flex" header="Actions" body={actionBodyTemplate}></Column>
            </DataTable>

            <Dialog
              className="p-3 background-white rounded-17"
              visible={showIncreaseModal}
              modal
              header={headerElement}
              style={{ maxWidth: "500px", minWidth: "400px" }}
              onHide={() => {
                if (!showIncreaseModal) return;
                setShowIncreaseModal(false);
              }}
            >
              <Formik
                enableReinitialize={true}
                initialValues={ListingLimitInitialValues}
                validationSchema={PropertyLimitSchema}
                onSubmit={handleSaveLimit}
              >
                <Form className="px-2">
                  <div className="mb-3">
                    <label htmlFor="name">Number of Listing</label>
                    <Field
                      className="form-control rounded-pill mt-1 color-dark"
                      type="number"
                      id="propertyLimit"
                      name="propertyLimit"
                      min={0}
                      step={1}
                      value={propertyLimit}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="propertyLimit" component={TextError} />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-lg button-navy  form-control rounded-pill mt-3"
                  >
                    Save
                  </button>
                </Form>
              </Formik>
            </Dialog>
          </section>
        </div>
      </div>
    </>
  );
}

export default UserList;
