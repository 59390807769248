import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { successToast, errorToast } from "../../../Utils/constant/commonFunction";
import listService from "./listService"

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  response: null,
  message: "",
  incBulletPointsData: "",
  getCountriesData: "",
  propertyDetail:"",
  autoUnautoPropertyListingData: "",
  getAllEventsOnBasisOFCityData:"",
  getListOfMandetsData:"",
  getUserAuditLogDataOfPropertyListingIdData:"",
  getCityData:"",
  latitudeLognitudeOfListingsData:"",
  connectionDataForAdminData:"",
  gettagsData:"",
  userActivatePropertyData:"",
  getAllCountryNpmDataData:"",
  firstTimeOpen: false,
}

/* Fetch logs Details   */
export const incBulletPoints = createAsyncThunk(
    "changeBulletsPoint",
    async (data, thunkAPI) => {
      try{
        return await listService.incBulletPoints(data);
      } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
      }
    }
  )
  
/* Fetch countries   */
export const getCountries = createAsyncThunk(
    "getCountryData",
    async (_, thunkAPI) => {
      try{
        return await listService.getCountries();
      } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

/* Fetch property by Id  */
  export const getPropertyById = createAsyncThunk(
    "getListingDataOnBehalfOfIdFromDatabase",
    async (data, thunkAPI) => {
      try{
        return await listService.getPropertyById(data);
      } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

/* Automate and Unautomate property listing  */
  export const autoUnautoPropertyListing = createAsyncThunk(
    "automatedAndUnautomatedListing",
    async (data, thunkAPI) => {
      try{
        return await listService.autoUnautoPropertyListing(data);
      } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  /* User Activate Property listing  */
  export const userActivateProperty = createAsyncThunk(
    "userActivateProperty",
    async (data, thunkAPI) => {
      try{
        return await listService.userActivateProperty(data);
      } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        errorToast(message);
        return thunkAPI.rejectWithValue(message)
      }
    }
  )
  
  /* Fetch Event listing data */
export const getAllEventsOnBasisOFCity = createAsyncThunk(
  "getAllEventsOnBasisOFCity",
  async (data, thunkAPI) => {
      try {
          return await listService.getAllEventsOnBasisOFCity(data);
      } catch (error) {
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return thunkAPI.rejectWithValue(message)
      }
  }
)

/* Fetch Get List Of Mandets  */
export const getListOfMandets = createAsyncThunk(
  "getListOfMandets",
  async (_, thunkAPI) => {
    try{
      return await listService.getListOfMandets();
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message)
    }
  }
)
 //Get User Audit Lo Data Of Property Listing Id
export const getUserAuditLogDataOfPropertyListingId = createAsyncThunk(
  "getUserAuditLogDataOfPropertyListingId",
  async (data, thunkAPI) => {
    try{
      return await listService.getUserAuditLogDataOfPropertyListingId(data);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message)
    }
  }
)

//Get City Data on behalf of Country
export const getCity = createAsyncThunk(
  "getCity",
  async (data, thunkAPI) => {
    try{
      return await listService.getCity(data);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message)
    }
  }
)

/* Get List Of Latitude and Longitude  */
export const latitudeLognitudeOfListings = createAsyncThunk(
  "latitudeLognitudeOfListings",
  async (_, thunkAPI) => {
    try{
      return await listService.latitudeLognitudeOfListings();
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message)
    }
  }
)

//Get List Of Hostaway Connected Users List
export const connectionDataForAdmin = createAsyncThunk(
  "connectionDataForAdmin",
  async (data, thunkAPI) => {
    try{
      return await listService.connectionDataForAdmin(data);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message)
    }
  }
)

/* Get List Of Tags  */
export const gettags = createAsyncThunk(
  "gettags",
  async (_, thunkAPI) => {
    try{
      return await listService.gettags();
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message)
    }
  }
)

/* Get Get All Country Npm Data  */
export const getAllCountryNpmData = createAsyncThunk(
  "getAllCountryNpmData",
  async (_, thunkAPI) => {
    try{
      return await listService.getAllCountryNpmData();
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message)
    }
  }
)

  /*  For Slice */
const listSlice = createSlice({
    name: "list",
    initialState,
    reducers: {
      reset: (state) => {
        state.isSuccess = false;
        state.isError = false;
        state.isLoading = false;
        state.message = "";
        state.response = null
      },
      setFirstTimeOpen: (state, action) => {
        state.firstTimeOpen = action.payload; // Set firstTimeOpen to the payload value
      }, 
    },
    extraReducers: (builder) => {
      builder
        .addCase(incBulletPoints.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(incBulletPoints.fulfilled, (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.isSuccess = true;
          state.response = payload.success;
          state.message = payload.message;
          successToast(payload.message)
          state.incBulletPointsData = payload;
        
        })
        .addCase(incBulletPoints.rejected, (state, { payload }) => {
          state.isError = true;
          state.isLoading = false;
          state.user = null;
          state.isSuccess = false;
          state.response = false;
          state.message = payload.message;
         
        })

        // Fetch countries
        .addCase(getCountries.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getCountries.fulfilled, (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.isSuccess = true;
          state.response = payload.success;
          state.message = payload.message;
          state.getCountriesData = payload;
        
        })
        .addCase(getCountries.rejected, (state, { payload }) => {
          state.isError = true;
          state.isLoading = false;
          state.user = null;
          state.isSuccess = false;
          state.response = false;
          state.message = payload.message;      
        })

        // Fetch propert Detail By Id
        .addCase(getPropertyById.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getPropertyById.fulfilled, (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.isSuccess = true;
          state.response = payload.success;
          state.message = payload.message;
          state.propertyDetail = payload;      
        })
        .addCase(getPropertyById.rejected, (state, { payload }) => {
          state.isError = true;
          state.isLoading = false;
          state.user = null;
          state.isSuccess = false;
          state.response = false;
          state.message = payload.message;         
        })

        // Automate and Unautomate the peoperty listing
        .addCase(autoUnautoPropertyListing.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(autoUnautoPropertyListing.fulfilled, (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.isSuccess = true;
          state.response = payload.success;
          state.message = payload.message;
          state.autoUnautoPropertyListingData = payload;
          successToast(payload.message);
        })
        .addCase(autoUnautoPropertyListing.rejected, (state, { payload }) => {
          state.isError = true;
          state.isLoading = false;
          state.user = null;
          state.isSuccess = false;
          state.response = false;
          state.message = payload.message;        
        })

           // Event listing data
           .addCase(getAllEventsOnBasisOFCity.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(getAllEventsOnBasisOFCity.fulfilled, (state, { payload }) => {
            state.isError = false;
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
            state.getAllEventsOnBasisOFCityData = payload;
        })
        .addCase(getAllEventsOnBasisOFCity.rejected, (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
            state.message = payload.message;
        })

        // Fetch Get List Of Mandets
        .addCase(getListOfMandets.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getListOfMandets.fulfilled, (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.isSuccess = true;
          state.response = payload.success;
          state.message = payload.message;
          state.getListOfMandetsData = payload;       
        })
        .addCase(getListOfMandets.rejected, (state, { payload }) => {
          state.isError = true;
          state.isLoading = false;
          state.user = null;
          state.isSuccess = false;
          state.response = false;
          state.message = payload.message;     
        })

       //Get User Audit Lo Data Of Property Listing Id
        .addCase(getUserAuditLogDataOfPropertyListingId.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getUserAuditLogDataOfPropertyListingId.fulfilled, (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.isSuccess = true;
          state.response = payload.success;
          state.message = payload.message;
          state.getUserAuditLogDataOfPropertyListingIdData = payload;   
        })
        .addCase(getUserAuditLogDataOfPropertyListingId.rejected, (state, { payload }) => {
          state.isError = true;
          state.isLoading = false;
          state.user = null;
          state.isSuccess = false;
          state.response = false;
          state.message = payload.message;       
        })

         //Get City data on behalf of Country
         .addCase(getCity.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getCity.fulfilled, (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.isSuccess = true;
          state.response = payload.success;
          state.message = payload.message;
          state.getCityData = payload;       
        })
        .addCase(getCity.rejected, (state, { payload }) => {
          state.isError = true;
          state.isLoading = false;
          state.user = null;
          state.isSuccess = false;
          state.response = false;
          state.message = payload.message;         
        })

        // Fetch Get List Of Latitude and Longitude
        .addCase(latitudeLognitudeOfListings.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(latitudeLognitudeOfListings.fulfilled, (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.isSuccess = true;
          state.response = payload.success;
          state.message = payload.message;
          state.latitudeLognitudeOfListingsData = payload;       
        })
        .addCase(latitudeLognitudeOfListings.rejected, (state, { payload }) => {
          state.isError = true;
          state.isLoading = false;
          state.user = null;
          state.isSuccess = false;
          state.response = false;
          state.message = payload.message;        
        })

        //Get List Of Hostaway Connected Users List
        .addCase(connectionDataForAdmin.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(connectionDataForAdmin.fulfilled, (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.isSuccess = true;
          state.response = payload.success;
          state.message = payload.message;
          state.connectionDataForAdminData = payload;       
        })
        .addCase(connectionDataForAdmin.rejected, (state, { payload }) => {
          state.isError = true;
          state.isLoading = false;
          state.user = null;
          state.isSuccess = false;
          state.response = false;
          state.message = payload.message;        
        })

          // Fetch Get List Of Tags
          .addCase(gettags.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(gettags.fulfilled, (state, { payload }) => {
            state.isError = false;
            state.isLoading = false;
            state.isSuccess = true;
            state.response = payload.success;
            state.message = payload.message;
            state.gettagsData = payload;         
          })
          .addCase(gettags.rejected, (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.user = null;
            state.isSuccess = false;
            state.response = false;
            state.message = payload.message;        
          })

        // User Activate Property listing
        .addCase(userActivateProperty.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(userActivateProperty.fulfilled, (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.isSuccess = true;
          state.response = payload.success;
          state.message = payload.message;
          state.userActivatePropertyData = payload;
          successToast(payload.message);
        })
        .addCase(userActivateProperty.rejected, (state, { payload }) => {
          state.isError = true;
          state.isLoading = false;
          state.user = null;
          state.isSuccess = false;
          state.response = false;
          state.message = payload.message;        
        })

            // Fetch Get All Country Npm Data
            .addCase(getAllCountryNpmData.pending, (state) => {
              state.isLoading = true;
            })
            .addCase(getAllCountryNpmData.fulfilled, (state, { payload }) => {
              state.isError = false;
              state.isLoading = false;
              state.isSuccess = true;
              state.response = payload.success;
              state.message = payload.message;
              state.getAllCountryNpmDataData = payload;           
            })
            .addCase(getAllCountryNpmData.rejected, (state, { payload }) => {
              state.isError = true;
              state.isLoading = false;
              state.user = null;
              state.isSuccess = false;
              state.response = false;
              state.message = payload.message;        
            })
    },
});

export const { reset,setFirstTimeOpen  } = listSlice.actions;
export default listSlice.reducer;