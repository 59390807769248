import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import settingService from "./settingService"

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  purchasedSubscriptions: "",
  downloadedPdfData: "",
}

/* Fetch purchased subscription   */
export const getPurchasedSubscriptions = createAsyncThunk(
  "getPurchagedSubscriptionData",
  async (_, thunkAPI) => {
    try {
      return await settingService.getPurchasedSubscriptions();
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message)
    }
  }
)

/* Download Pdf   */
export const downloadPdf = createAsyncThunk(
  'downloadPdf',
  async (data, thunkAPI) => {
    try {
      const response = await settingService.downloadPdf(data);
      return response;
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


/*  For Slice */
const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    reset: (state) => {
      state.isSuccess = false;
      state.isError = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
    
      // Fetch purchased subscriptions
      .addCase(getPurchasedSubscriptions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPurchasedSubscriptions.fulfilled, (state, { payload }) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.message = payload.message;
        state.purchasedSubscriptions = payload;

      })
      .addCase(getPurchasedSubscriptions.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = payload.message;

      })

      //  Download Pdf
      .addCase(downloadPdf.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(downloadPdf.fulfilled, (state, { payload }) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.message = payload.message;
        state.downloadedPdfData = payload;
      })
      .addCase(downloadPdf.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = payload.message;
      })
  },
});

export const { reset } = settingSlice.actions;
export default settingSlice.reducer;