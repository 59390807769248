import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { getUser } from "../../Redux/Features/LocalStorage";
import { useDispatch } from "react-redux";
import { logout } from "../../Redux/Features/authReducer/authSlice";
import Swal from "sweetalert2";
import Cookies from 'js-cookie';
import { DASHBOARD, SETTINGS } from "../../Routes/Constant";
import { useSelector } from "react-redux";
import { arrayBufferToBase64 } from "../../Utils/constant/commonFunction";
import Notification from "./Notification";
import { GetUserDetailsApi } from "../../Redux/Features/authReducer/authSlice";
import { fetchNotificationApi } from "../../Redux/Features/notificationReducer/notificationSlice";

function HeaderPage() {
  const { GetUserDetailsData } = useSelector((state) => state.Auth);
  const { fetchedNotifications } = useSelector((state) => state.Notification);
  const User = getUser();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetUserDetailsApi());
    dispatch(fetchNotificationApi());
  }, []);

  const LogoutApp = () => {
    const logoutData = {
      username: User?.name,
    };

    Swal.fire({
      title: "Success",
      text: "User logged out successfully",
      icon: "success",
      timer: 1300,
      showConfirmButton: false,
      width: "300px",
      customClass: { popup: "small-swal" },
    });
    setTimeout(() => {
      dispatch(logout(logoutData));
    }, 1100);
    setTimeout(() => {
      localStorage.clear();
      Cookies.remove("otamiserToken");
      Cookies.remove("otamiserToken2");
    }, 1100);
  };
  return (
    <header className="fixed-top">
      <section id="header" className="h-100">
        <nav className="navbar navbar-expand-lg background-white py-0 px-0 border-bottom border-muted">
          <div className="container-fluid">
            <NavLink to={DASHBOARD}>
              <span className="logo-img"></span>
            </NavLink>

            <button
              class="navbar-toggler navbar-toggler-right d-lg-none align-self-center border-0 p-0"
              id="sidebar-toggle"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasWithBothOptions"
              aria-controls="offcanvasWithBothOptions"
            >
              <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.0996 18.5996H4.09961M15.5996 12.5996H4.09961M21.0996 6.59961H4.09961" stroke="#202744" stroke-width="2.1" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </button>

            <Notification data={fetchedNotifications?.data || []} />

            <div className="header-profile dropdown">
              <NavLink
                to={"/"}
                className="d-flex justify-content-center align-items-center text-decoration-none"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <h6 className="mx-2 mt-2 mb-0 text-navy exMedium ms-4 me-3 d-md-block d-none">
                  {GetUserDetailsData?.data?.fname}
                </h6>

                <img
                  src={
                    (GetUserDetailsData?.data?.userImage?.data &&
                      arrayBufferToBase64(
                        GetUserDetailsData?.data?.userImage?.data
                      )) ||
                    process.env.PUBLIC_URL +
                    "/Assets/images/user_placeholder.png"
                  }
                  className="profile-img border border-3 border-light shadow-sm"
                  alt=""
                />
              </NavLink>

              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <NavLink to={SETTINGS} className="dropdown-item color-dark">
                    Settings
                  </NavLink>
                </li>
                <li>
                  <hr class="dropdown-divider color-muted" />
                </li>
                <li onClick={(e) => LogoutApp(e)}>
                  <NavLink className="dropdown-item color-dark">Logout</NavLink>
                </li>
              </ul>
            </div>
          </div>        
        </nav>    
      </section>
    </header>
  );
}

export default HeaderPage;
